import React, { useEffect, useState, useContext } from "react";
import GetPower from "../api/GetPower";
import { LinearProgress } from "@mui/material";
import ActivePowerChart from "./ActivePowerChart";
import { Box } from "@mui/system";
import { ParamsContext } from "../pages/AnalyzePage";
import WeekPicker from "./WeekPicker";
const ActivePower = () => {
  const { id, aggr } = useContext(ParamsContext);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [dataset, setDataset] = useState();
  const [loading, setLoading] = useState();
  const handleChangeDay = (val) => {
    setPickedDate(val);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      GetPower(id, aggr, "active", pickedDate)
        .then((res) => {
          setDataset(res.data);
          console.log(res.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }, 500);

    return () => clearTimeout(timer);
  }, [pickedDate, id]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          maxHeight: "10rem",
        }}
      >
        <WeekPicker handleChangeDay={handleChangeDay} pickedDate={pickedDate} />
      </Box>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        dataset && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "35rem",
              height: "35rem",
            }}
          >
            <ActivePowerChart dataset={dataset} />
          </Box>
        )
      )}
    </>
  );
};

export default ActivePower;

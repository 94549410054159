import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { InputAdornment } from "@mui/material";
const StyledTextFieldMini = styled(TextField)(({ theme }) => ({
  marginTop: "0",
  marginBottom: "-0.2rem",

  "& .MuiInputBase-input": {
    color: "#212528",
  },
  "& label.Mui-focused": {
    color: "#212528",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1rem",
    color: "#171D22",
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.tertiary,
  },
}));
const TariffDatePicker = ({ startDate, setStartDate, label, setData }) => {
  const theme = useTheme();
  return (
    <DatePicker
      views={["day"]}
      label={label}
      disableFuture={false}
      inputFormat="dd. MMM. yyyy"
      value={startDate}
      OpenPickerButtonProps={{
        style: { color: theme.palette.primary.surface },
      }}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "row",
          "& .MuiPickersDay-root": {
            // backgroundColor: theme.palette.primary.accentActive,
            color: theme.palette.primary.dark,
            "&.Mui-selected": {
              backgroundColor: theme.palette.primary.accentActive,
              "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.accent,
              },
            },
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.accent,
            },
          },
        },
      }}
      onChange={(newValue) => {
        setData(newValue);
        setStartDate(newValue);
      }}
      renderInput={(params) => {
        return (
          <StyledTextFieldMini
            {...params}
            variant="standard"
            helperText={null}
          />
        );
      }}
    />
  );
};
export default TariffDatePicker;

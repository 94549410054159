import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { useTheme } from "@emotion/react";

const PowerPeakChart = ({ datasetPeak, maxValue, ordered, initialValue }) => {
  console.log(datasetPeak.length);
  const theme = useTheme();

  const largeViewport = useMediaQuery("(min-width:1260px)");

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      //
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.surface,
            borderRadius: "15px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography variant="h3">Dzień</Typography>
            <Typography variant="p">
              {format(new Date(label), "eeee dd/MM/yyyy", { locale: pl })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3">Szczyt mocy</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="p">
                {payload[0].unit === "PLN"
                  ? payload[0].value
                  : payload[0].value / 1000}
              </Typography>
              <Typography
                variant="p"
                sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
              >
                {payload[0].unit}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  if (datasetPeak.length > 0) {
    return (
      <ResponsiveContainer>
        <BarChart
          data={datasetPeak}
          margin={{
            top: 50,
            right: 30,
            left: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="4 4" />
          <XAxis
            tick={{ fill: theme.palette.text.secondary }}
            xAxisId={0}
            dataKey="x"
            hide
          />
          <XAxis
            tick={{ fill: theme.palette.text.secondary }}
            xAxisId={1}
            dataKey="x"
            label={{
              fill: theme.palette.text.secondary,
              value: "Dzień miesiąca",
              angle: 0,
              fontSize: "15",
              offset: "-5",
              fontWeight: "bold",
              position: "bottom",
            }}
            tickFormatter={(value) => {
              return format(new Date(value), "dd/MM");
            }}
          />
          <YAxis
            tick={{ fill: theme.palette.text.secondary }}
            tickFormatter={(value) => {
              //
              return (value / 1000)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }}
            label={{
              fill: theme.palette.text.secondary,
              value: "Moc [kW]",
              angle: 0,
              fontSize: "15",
              offset: "15",
              fontWeight: "bold",
              position: "top",
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            barSize={20}
            name="Max"
            xAxisId={0}
            unit="kW"
            dataKey="y"
            fill={theme.palette.primary.accentActive}
          />

          <ReferenceLine
            y={ordered}
            isFront={true}
            ifOverflow="extendDomain"
            label={{
              position: "insideBottomRight",
              value: `Moc zamówiona: ${ordered / 1000} kW`,
              fill: theme.palette.primary.alert,
              fontSize: 14,
            }}
            stroke={theme.palette.primary.alert}
          />
          <ReferenceLine
            y={maxValue}
            isFront={true}
            ifOverflow="extendDomain"
            label={{
              position: "insideBottomRight",
              value: `Maksymalny pobór: ${(maxValue / 1000).toFixed(2)} kW`,
              fill: theme.palette.text.primary,
              fontSize: 14,
            }}
            stroke={theme.palette.text.primary}
          />
          {initialValue && (
            <ReferenceLine
              y={ordered - initialValue * 1000}
              isFront={true}
              ifOverflow="extendDomain"
              label={{
                position: "top",
                value: `Po obniżeniu: ${(ordered / 1000 - initialValue).toFixed(
                  2
                )} kW`,
                fill: theme.palette.primary.success,
                fontSize: 14,
              }}
              stroke={theme.palette.primary.success}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          marginTop: "10%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", marginTop: "-10%" }}
        >
          Brak danych za wybrany okres
        </Typography>
      </Box>
    );
  }
};
export default PowerPeakChart;

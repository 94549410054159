import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  Legend,
  Label,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { format } from "date-fns";
import plLocale from "date-fns/locale/pl";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { pl } from "date-fns/locale";
import { useTheme } from "@emotion/react";
const ReactivePowerChart = ({
  dataset,
  maxValuePositive,
  maxValueNegative,
}) => {
  const theme = useTheme();
  const largeViewport = useMediaQuery("(min-width:1264px)");

  //

  // const findMax = (arr1, arr2) =>{
  //   const dataMax = Math.max(...arr1.map((i)=> i.y))
  //   const dataMin = Math.min(...arr2.map((i)=> i.y))
  //   return {max:arr1.length > 0 ? dataMax : 0, min:arr2.length > 0 ? dataMin : 0}
  // }
  //

  const gradientOffset = (val) => {
    const dataMax = Math.max(...val.map((i) => i.y));
    const dataMin = Math.min(...val.map((i) => i.y));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };
  const off = gradientOffset(dataset);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      //
      //

      return (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.surface,
            borderRadius: "15px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography variant="h3">Godzina</Typography>
            <Typography variant="p">
              {format(new Date(label), "EEEE, HH:mm", { locale: pl })}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3">
              {payload[0].value > 0
                ? "Moc bierna indukcyjna"
                : "Moc bierna pojemnościowa"}
            </Typography>

            <Typography variant="p">
              {payload[0].value.toString().length > 6
                ? `${Math.abs(payload[0].value / 1000000)} MVAr`
                : [
                    payload[0].value.toString().length > 3
                      ? `${Math.abs(payload[0].value / 1000)} kVAr`
                      : `${Math.abs(payload[0].value)} VAr`,
                  ]}
            </Typography>
          </Box>
        </Box>
      );
    }

    return null;
  };

  if (dataset.length > 0) {
    return (
      <>
        <ResponsiveContainer width="80%">
          <AreaChart
            data={dataset}
            margin={{
              top: 50,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tick={{ fill: theme.palette.text.secondary }}
              minTickGap={50}
              dataKey="x"
              label={{
                fill: theme.palette.text.secondary,
                value: "Godzina i dzień tygodnia",
                angle: 0,
                fontSize: "15",
                offset: "-5",
                fontWeight: "300",
                position: "insideBottomLeft",
              }}
              tickFormatter={(value) => {
                //
                //
                if (value) {
                  return format(new Date(value), "HH:mm EEEE", { locale: pl });
                }
              }}
            />
            <YAxis
              tick={{ fill: theme.palette.text.secondary }}
              tickFormatter={(value) => {
                return Math.abs(value / 1000);
              }}
              label={{
                fill: theme.palette.text.secondary,
                value: `Moc [kVAr]`,
                angle: 0,
                fontSize: "15",
                offset: "15",
                fontWeight: "300",
                position: "top",
              }}
            />

            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset={off}
                  stopColor={theme.palette.primary.accentActive}
                  stopOpacity={1}
                />
                <stop
                  offset={off}
                  stopColor={theme.palette.primary.alert}
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            <Area
              // dot={true}
              type="monotone"
              dataKey="y"
              strokeWidth={0}
              name="Moc bierna indukcyjna"
              fill={"url(#splitColor)"}
              activeDot={{ r: 8 }}
            />
            {/* <Area
            dot={true}
            type="monotone"
            dataKey="y1"
            // data={parseReactivePowers(dataset)[1]}
            strokeWidth={0}
            // stroke='#fff'
            name="Moc bierna pojemnościowa"
            fill={theme.palette.primary.alert}
            activeDot={{ r: 8 }}
          /> */}

            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.primary.accentActive }}
            >
              Indukcyjna
            </Typography>
            <Typography
              variant="p"
              sx={{ color: theme.palette.primary.accentActive }}
            >
              Maksymalny pobór: {(maxValuePositive / 1000).toFixed(2)} kVAr
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h3"
              sx={{ color: theme.palette.primary.alert }}
            >
              Pojemnościowa
            </Typography>
            <Typography variant="p" sx={{ color: theme.palette.primary.alert }}>
              Maksymalny pobór: {(maxValueNegative / 1000).toFixed(2)} kVAr
            </Typography>
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          marginTop: "10%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", marginTop: "-10%" }}
        >
          Brak danych za wybrany okres
        </Typography>
      </Box>
    );
  }
};
export default ReactivePowerChart;

import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "./utils/AuthManager";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Main from "./pages/Main";
import Dashboard from "./pages/Dashboard";
import PV from "./pages/PV";
import Profile from "./pages/Profile";
import Anomalies from "./pages/Anomalies";
import Reports from "./pages/Reports";
import Settings from "./pages/Settings";
import AnalyzePage from "./pages/AnalyzePage";
import Recommendations from "./pages/Recommendations";
function App() {
  const { restore, state } = useContext(AuthContext);
  const [isTokenRestored, setIsTokenRestored] = useState(false);

  useEffect(() => {
    restore();
    setIsTokenRestored(true);
  }, []);

  return (
    <>
      {isTokenRestored && (
        <Routes>
          {" "}
          <Route
            path="login"
            element={state.token === null ? <LoginPage /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={state.token !== null ? <Main /> : <Navigate to="login" />}
          >
            <Route index element={<Dashboard />} />
            <Route path="rekomendacje" element={<Recommendations />} />
            <Route path="analiza" element={<AnalyzePage />} />
            <Route path="prosument" element={<PV />} />
            <Route path="anomalie" element={<Anomalies />} />
            <Route path="raporty" element={<Reports />} />
            <Route path="konfiguracja" element={<Settings />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/login" />}></Route>
        </Routes>
      )}
    </>
  );
}

export default App;

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "@emotion/styled";
import { Tab } from "@mui/material";
import { useTheme } from "@emotion/react";
import StructureConfig from "../components/StructureConfig";
import PriceListConfig from "../components/PriceListConfig";
const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "1.5rem",
  "&.Mui-selected": {
    color: theme.palette.primary.accentActive,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ height: "100%", width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
const Settings = ({}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: theme.palette.primary.accent }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            indicatorColor={theme.palette.primary.accentActive}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.primary.accentActive,
              },
            }}
          >
            <StyledTab label="Struktura systemu" {...a11yProps(0)} />
            <StyledTab label="Cenniki" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <StructureConfig />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PriceListConfig />
      </TabPanel>
    </Box>
  );
};
export default Settings;

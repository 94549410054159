import { api } from "../utils/AuthManager";
import format from "date-fns/format";
const GetReport = async (
  id,
  aggr,
  anomaly,
  end_date,
  energy,
  power,
  start_date,
  type,
  unit
) => {
  return api
    .get(`/api/report/${parseInt(id)}/`, {
      responseType: type === "pdf" && "blob",
      params: {
        aggr: aggr,
        anomaly: anomaly,
        end_date: format(end_date, "yyyy-MM-dd"),
        energy: energy,
        power: power,
        start_date: format(start_date, "yyyy-MM-dd"),
        type: type,
        unit: unit,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetReport;

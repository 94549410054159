import { api } from "../utils/AuthManager";
import startOfWeek from "date-fns/startOfWeek";
import format from "date-fns/format";
const GetTHD = async (id, date) => {
  return api
    .get(`/api/thd/${parseInt(id)}`, {
      params: {
        date: format(startOfWeek(date), "yyyy-MM-dd"),
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetTHD;

import logo from "../utils/p.stream_logo.png";
import logoAlt from "../utils/powerstream-logo.png";

export const getPalette = (mode) => ({
  typography: {
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  palette: {
    mode,
    ...(mode === "dark"
      ? {
          primary: {
            main: "#1976D2",
            mainBackground: "#171D22",
            dark: "#ffffff",
            surface: "#292E33",
            accentActive: "#1976D2",
            accent: "rgba(25,118,210,0.25)",
            alert: "#FF4141",
            red: "#AE3D3F",
            success: "#7EFF69",
            logo: logo,
            yellow: "#FFC90B",
          },
          text: {
            primary: "rgba(255,255,255,0.9)",
            secondary: "rgba(255,255,255,0.7)",
            tertiary: "rgba(0,0,0,0.9)",
          },
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              laptop: 1024,
              lg: 1264,
              xl: 1536,
            },
          },
        }
      : {
          primary: {
            main: "#F4F7FE",
            dark: "#171D22",
            mainBackground: "#171D22",
            surface: "#ffffff",
            accentActive: "#1976D2",
            accent: "rgba(25,118,210,0.25)",
            alert: "#FF4141",
            red: "#AE3D3F",
            success: "#008000",
            logo: logoAlt,
            yellow: "#FFC90B",
          },
          text: {
            primary: "rgba(23,29,34,0.9)",
            secondary: "rgba(23,29,34,0.7)",
            tertiary: "rgba(255,255,255,0.9)",
          },
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              lg: 1264,
              xl: 1536,
            },
          },
        }),
  },
});

import React, { useEffect, useState } from "react";
import GetAllPricelists from "../api/GetAllPricelists";
import { Box, Typography, Grid, Button } from "@mui/material";
import PriceListsAccordion from "./PriceListsAccordion";
import { useTheme } from "@emotion/react";
import PostPriceList from "../api/PostPriceList";
import AddPriceList from "./AddPriceList";
import GetAllTariffs from "../api/GetAllTariffs";

const PriceListConfig = () => {
  const [open, setOpen] = useState(false);
  const [pricelists, setPricelists] = useState();
  const [reload, setReload] = useState(false);
  const [allTariffs, setAllTariffs] = useState();
  const theme = useTheme();
  useEffect(() => {
    GetAllTariffs()
      .then((res) => {
        setAllTariffs(res.data);
      })
      .catch((err) => console.log(err));
    GetAllPricelists()
      .then((res) => {
        console.log(res);
        setPricelists(res.data);
      })
      .catch((err) => console.log(err));
  }, [reload]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePostPricelist = (data) => {
    PostPriceList(data)
      .then((res) => {
        handleClose();
        setReload((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box sx={{ marginBottom: "2rem" }}>
          <Typography variant="h1">Twoje cenniki</Typography>
          <Button
            variant="outlined"
            onClick={handleOpen}
            sx={{
              marginTop: "1rem",
              color: theme.palette.primary.accentActive,
              borderColor: theme.palette.primary.accentActive,
              fontWeight: "bold",
              "&:hover": {
                borderColor: theme.palette.primary.accent,
              },
            }}
          >
            Dodaj nowy
          </Button>
        </Box>
        {pricelists && <PriceListsAccordion pricelists={pricelists} />}
      </Grid>
      {allTariffs && (
        <AddPriceList
          allTariffs={allTariffs}
          handleClose={handleClose}
          open={open}
          handlePostPricelist={handlePostPricelist}
        />
      )}
    </>
  );
};

export default PriceListConfig;

import { useTheme } from "@emotion/react";
import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  ComposedChart,
  Cell,
} from "recharts";
import format from "date-fns/format";
import { Box, Typography } from "@mui/material";
import { pl } from "date-fns/locale";
import { ParamsContext } from "../pages/AnalyzePage";

const CustomTooltip = ({ active, payload, label, aggrType, aggr }) => {
  const theme = useTheme();
  const COLORS = ["#1976D2", "#FF4141", "#7EFF69"];
  if (active && payload && payload.length) {
    //
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.surface,
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">Dzień</Typography>
          <Typography variant="p">
            {aggrType === "day"
              ? format(new Date(label), "HH:mm")
              : aggrType === "month"
              ? format(new Date(label), "eeee dd/MM/yyyy", { locale: pl })
              : aggrType === "year" &&
                format(new Date(label), "MMM/yyyy", { locale: pl })}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3">Pobór energii</Typography>
          {payload.length > 1 ? (
            <>
              {payload.map((entry, index) => {
                // console.log(entry);
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color:
                          entry.unit === "PLN"
                            ? "#FFC90B"
                            : aggr === "meter"
                            ? entry.payload.energy_zone === "2"
                              ? theme.palette.primary.alert
                              : entry.payload.energy_zone === "1"
                              ? theme.palette.primary.success
                              : entry.payload.energy_zone === "0" &&
                                theme.palette.primary.main
                            : entry.fill,
                      }}
                    >
                      {entry.unit === "PLN"
                        ? parseFloat(entry.value).toFixed(2)
                        : (entry.value / 1000).toFixed(3)}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
                    >
                      {entry.unit}
                    </Typography>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  color:
                    payload[0].unit === "PLN"
                      ? "#FFC90B"
                      : payload[0].payload.energy_zone === "2"
                      ? theme.palette.primary.alert
                      : payload[0].payload.energy_zone === "1"
                      ? theme.palette.primary.success
                      : payload[0].payload.energy_zone === "0" &&
                        theme.palette.primary.main,
                }}
              >
                {payload[0].unit === "PLN"
                  ? parseInt(payload[0].value).toFixed(2)
                  : (payload[0].value / 1000).toFixed(3)}
              </Typography>
              <Typography
                variant="p"
                sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
              >
                {payload[0].unit}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return null;
};

const EnergyChart = ({ aggrType, dataset }) => {
  const { aggr } = useContext(ParamsContext);
  const theme = useTheme();
  const COLORS = ["#1976D2", "#FF4141", "#7EFF69"];
  if (dataset.dataset.length > 0) {
    return (
      <ResponsiveContainer>
        <ComposedChart
          width={730}
          height={250}
          data={dataset.dataset}
          margin={{
            top: 50,
            right: 50,
            left: 50,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            label={{
              fill: theme.palette.text.secondary,
              value:
                aggrType === "day"
                  ? "Interwał 15 minut"
                  : aggrType === "month"
                  ? "Dzień"
                  : aggrType === "year" && "Miesiąc",
              angle: 0,
              fontSize: "15",
              offset: "5",
              fontWeight: "bold",
              position: "bottom",
            }}
            tickFormatter={(tick) => {
              if (aggrType === "day") {
                return format(tick, "HH:mm");
              }
              if (aggrType === "month") {
                return format(tick, "dd");
              }
              if (aggrType === "year") {
                return format(tick, "yyyy");
              }
            }}
          />
          <YAxis
            tickFormatter={(tick) => {
              return (tick / 1000).toFixed(0);
            }}
            orientation="left"
            type="number"
            yAxisId={0}
            domain={["dataMin", "dataMax"]}
            label={{
              fill: theme.palette.text.secondary,
              value: "Pobór energii [kWh]",
              angle: 0,
              fontSize: "15",
              offset: "15",
              fontWeight: "bold",
              position: "top",
            }}
          />
          <YAxis
            yAxisId={1}
            hide
            type="number"
            domain={[0, "dataMax"]}
            orientation="right"
            tickFormatter={(tick) => {
              console.log(tick);
              return tick;
            }}
            label={{
              fill: theme.palette.text.secondary,
              // value: "Pobór energii [kWh]",
              angle: 0,
              fontSize: "15",
              offset: "15",
              fontWeight: "bold",
              position: "top",
            }}
          />
          <Tooltip
            content={<CustomTooltip aggrType={aggrType} aggr={aggr} />}
            cursor={{ fill: "transparent" }}
          />

          {aggrType === "day" && aggr === "meter" ? (
            <Bar dataKey="y" unit="kWh" yAxisId={0}>
              {dataset.dataset.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.energy_zone === "0"
                        ? COLORS[0]
                        : entry.energy_zone === "1"
                        ? COLORS[2]
                        : entry.energy_zone === "2" && COLORS[1]
                    }
                  />
                );
              })}
            </Bar>
          ) : (
            <>
              <Bar
                dataKey="y"
                stackId="a"
                unit="kWh"
                fill={COLORS[0]}
                yAxisId={0}
              />
              <Bar
                dataKey="y_1"
                stackId="a"
                unit="kWh"
                fill={COLORS[2]}
                yAxisId={0}
              />
              <Bar
                dataKey="y_2"
                stackId="a"
                unit="kWh"
                fill={COLORS[1]}
                yAxisId={0}
              />
            </>
          )}
          <Line
            dataKey="energy_price"
            yAxisId={1}
            stroke="#FFC90B"
            strokeWidth={2}
            name="Koszt"
            unit="PLN"
          />
          <Line
            dataKey="energy_price_1"
            yAxisId={1}
            stroke="#FFC90B"
            strokeWidth={2}
            name="Koszt"
            unit="PLN"
          />
          <Line
            dataKey="energy_price_2"
            yAxisId={1}
            stroke="#FFC90B"
            strokeWidth={2}
            name="Koszt"
            unit="PLN"
          />
          <Line
            dataKey="energy_price_3"
            yAxisId={1}
            stroke="#FFC90B"
            strokeWidth={2}
            name="Koszt"
            unit="PLN"
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          marginTop: "10%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", marginTop: "-10%" }}
        >
          Brak danych za wybrany okres
        </Typography>
      </Box>
    );
  }
};

export default EnergyChart;

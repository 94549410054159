import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import GetProfile from "../api/GetProfile";
import GetAllFacilities from "../api/GetAllFacilities";
import GetAllGroups from "../api/GetAllGroups";
import GetAllMeters from "../api/GetAllMeters";
import { useTheme } from "@emotion/react";
import ProfilePieChart from "../components/ProfilePieChart";
import { ParamsContext } from "./Recommendations";
const Profile = () => {
  const { id, aggr } = useContext(ParamsContext);
  const theme = useTheme();
  const [userTariff, setUserTariff] = useState();
  const [dataset, setDataset] = useState();
  const [arr, setArr] = useState();
  const [loading, setLoading] = useState();
  const handleGetProfile = () => {
    GetProfile(id, aggr)
      .then((res) => {
        console.log(res);
        setDataset(res.data.tariffs);
        setUserTariff(res.data.user_tariff[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      handleGetProfile();
    }, 3000);
    return () => clearTimeout(timer);
  }, [id, aggr]);

  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}
    >
      {loading && (
        <>
          <Typography variant="h2">Trwa analiza profilu zużycia</Typography>
          <LinearProgress />
        </>
      )}
      <Grid container>
        {!loading && userTariff && (
          <>
            <Grid item lg={6} md={12} sx={{ width: "100%", height: "20rem" }}>
              <Typography variant="h2">
                Twoja obecna taryfa: {userTariff.name}
              </Typography>
              <Typography variant="p">
                Całkowity koszt w poprzednim miesiącu:{" "}
                {userTariff.total_cost.toFixed(2)} PLN
              </Typography>
              <ProfilePieChart
                width={"10rem"}
                height={"10rem"}
                dataset={userTariff.dataset}
              />
            </Grid>

            {dataset && (
              <>
                <Grid container item lg={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="p">Rekomendacja dla Ciebie</Typography>
                    <Typography
                      variant="h2"
                      sx={{
                        color: dataset.find(
                          (e) => e.total_cost < userTariff.total_cost
                        )
                          ? theme.palette.primary.alert
                          : theme.palette.primary.success,
                      }}
                    >
                      {dataset.find((e) => e.total_cost < userTariff.total_cost)
                        ? "Możesz zmienić taryfę"
                        : "Twoja taryfa jest optymalna"}
                    </Typography>
                    <Divider flexItem color={theme.palette.primary.main} />
                  </Box>
                  {dataset.map((entry, index) => {
                    return (
                      <Grid item lg={12} md={12} sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="h2">{entry.name}</Typography>
                          <Typography variant="p">
                            Symulowany koszt za poprzedni miesiąc:{" "}
                            {entry.total_cost.toFixed(2)} PLN
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{
                              color:
                                entry.total_cost < userTariff.total_cost
                                  ? theme.palette.primary.success
                                  : entry.total_cost > userTariff.total_cost &&
                                    theme.palette.primary.alert,
                            }}
                          >
                            {entry.total_cost < userTariff.total_cost
                              ? `Szacowana oszczędność: ${(
                                  userTariff.total_cost - entry.total_cost
                                ).toFixed(2)} PLN`
                              : entry.total_cost > userTariff.total_cost &&
                                `Szacowana strata: ${(
                                  entry.total_cost - userTariff.total_cost
                                ).toFixed(2)} PLN`}
                          </Typography>
                        </Box>
                        {/* <ProfilePieChart
                 width={"10rem"}
                 height={"10rem"}
                 dataset={entry.dataset}
               /> */}
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Profile;

import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTheme } from "@emotion/react";
const RadioButtons = ({ arr, handleChangeAggr, aggr, label }) => {
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel
        sx={{ "&.Mui-focused": { color: "white" } }}
        id="demo-row-radio-buttons-group-label"
      >
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(event) => handleChangeAggr(event.target.value)}
        value={aggr}
      >
        {arr.map((entry, index) => {
          return (
            <FormControlLabel
              sx={{ color: theme.palette.primary.dark }}
              key={index}
              value={entry.value}
              control={<Radio color="default" />}
              label={entry.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;

import { api } from "../utils/AuthManager";
const PatchMeter = async (id, data) => {
  return api
    .patch(`/api/meter/${id}/`, data)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default PatchMeter;

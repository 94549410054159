import React, { useState, createContext } from "react";
import RadioButtons from "../components/RadioButtons";
import GroupSelector from "../components/GroupSelector";
import GetAllFacilities from "../api/GetAllFacilities";
import GetAllGroups from "../api/GetAllGroups";
import GetAllMeters from "../api/GetAllMeters";
import { Box, Typography } from "@mui/material";
import ChartTabs from "../components/ChartTabs";
import MultiSelector from "../components/MultiSelector";
import { useEffect } from "react";
import { VolunteerActivismOutlined } from "@mui/icons-material";
import RecommendationTabs from "../components/RecommendationTabs";
export const ParamsContext = createContext();
const Recommendations = () => {
  const [id, setId] = useState();
  const [meters, setMeters] = useState();
  const [groups, setGroups] = useState();
  const [facilities, setFacilities] = useState();
  const [aggr, setAggr] = useState("");
  const [name, setName] = useState();

  const handleGetUnits = (val) => {
    GetAllGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => console.log(err));

    GetAllMeters()
      .then((res) => {
        // console.log(res);
        setMeters(res.data);
        if (res.data.length === 1) {
          setId(res.data[0].id);
          setAggr("meter");
        }
      })
      .catch((err) => console.log(err));

    GetAllFacilities()
      .then((res) => {
        console.log(res.data);
        setFacilities(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    handleGetUnits();
  }, []);
  const handleSetAggr = (val) => {
    console.log(val);
    setAggr(val.type);
    setId(val.id);
    setName(val.name);
  };

  return (
    <ParamsContext.Provider value={{ id, aggr, meters, facilities, groups }}>
      <Box
        sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 3 }}
      >
        {" "}
        {meters && meters.length > 1 && groups && facilities ? (
          <>
            {!id && <Typography variant="h3">Wybierz z listy</Typography>}
            <MultiSelector
              handleChange={handleSetAggr}
              aggr={aggr}
              id={id}
              meters={meters}
              facilities={facilities}
              groups={groups}
              name={name}
            />
          </>
        ) : (
          meters &&
          meters[0] && <Typography variant="h3">{meters[0].name}</Typography>
        )}
        {id && <RecommendationTabs id={id} />}
      </Box>
    </ParamsContext.Provider>
  );
};

export default Recommendations;

import { useTheme } from "@emotion/react";
import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  ComposedChart,
  Cell,
} from "recharts";
import format from "date-fns/format";
import { Box, Typography } from "@mui/material";
import { pl } from "date-fns/locale";
import { ParamsContext } from "../pages/AnalyzePage";
import { Rectangle } from "@mui/icons-material";

const CustomTooltip = ({ active, payload, label, sumAvg }) => {
  const theme = useTheme();
  const COLORS = ["#1976D2", "#FF4141", "#7EFF69"];
  if (active && payload && payload.length) {
    let sum =
      payload[0].payload.energy_price_daily_sum +
      payload[0].payload.power_fee_daily_sum;
    //
    console.log(payload);
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.surface,
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h3" sx={{ color: theme.palette.primary.alert }}>
          {payload[0].payload.anomaly && "Anomalia!"}
        </Typography>
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="p">
            {format(new Date(label), "eeee dd/MM/yyyy", { locale: pl })}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h3">Pobór</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="p">
              {(payload[0].value / 1000).toFixed(3)}
            </Typography>
            <Typography
              variant="p"
              sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
            >
              {payload[0].unit}
            </Typography>
          </Box>
          <Typography variant="h3">Łączny koszt</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="p">
              {sum.toFixed(2)}{" "}
              {payload[0].payload.anomaly && sum !== 0 && (
                <span style={{ color: theme.palette.primary.alert }}>
                  (
                  {sum > sumAvg
                    ? `+${((sum * 100) / sumAvg - 100).toFixed(2)} %`
                    : `-${(100 - (sum * 100) / sumAvg).toFixed(2)} %`}
                  )
                </span>
              )}
            </Typography>
            <Typography
              variant="p"
              sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
            >
              PLN
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

const CustomBar = (props) => {
  const { fill, x, y, width, height, anomaly } = props;
  if (anomaly === true) {
    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="none"
        fill={"rgba(255,255,255,0.06)"}
      />
    );
  }
};
const AnomaliesChart = ({ dataset }) => {
  console.log(dataset);
  console.log("hello world");
  const theme = useTheme();
  let sumAvg = dataset.energy_price_avg + dataset.power_fee_avg;
  const COLORS = ["#1976D2", "#FF4141", "#7EFF69"];
  if (dataset.dataset.length > 0) {
    return (
      <ResponsiveContainer>
        <BarChart
          width={730}
          height={250}
          data={dataset.dataset}
          margin={{
            top: 50,
            right: 50,
            left: 50,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="x"
            label={{
              fill: theme.palette.text.secondary,
              value: "Dzień",
              angle: 0,
              fontSize: "15",
              offset: "5",
              fontWeight: "bold",
              position: "bottom",
            }}
            tickFormatter={(tick) => {
              return format(new Date(tick), "eeee dd/MM", { locale: pl });
            }}
          />
          <YAxis
            tickFormatter={(tick) => {
              return (tick / 1000).toFixed(0);
            }}
            type="number"
            domain={["dataMin", "dataMax"]}
            label={{
              fill: theme.palette.text.secondary,
              value: "Pobór energii [kWh]",
              angle: 0,
              fontSize: "15",
              offset: "15",
              fontWeight: "bold",
              position: "top",
            }}
          />

          <Tooltip
            content={<CustomTooltip sumAvg={sumAvg} />}
            cursor={{ fill: "transparent" }}
          />

          <Bar dataKey="y" unit="kWh" background={<CustomBar />}>
            {dataset.dataset.map((entry, index) => {
              console.log(entry);
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.anomaly
                      ? theme.palette.primary.alert
                      : theme.palette.primary.main
                  }
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          marginTop: "10%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", marginTop: "-10%" }}
        >
          Brak danych za wybrany okres
        </Typography>
      </Box>
    );
  }
};

export default AnomaliesChart;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthManager } from "./utils/AuthManager";
import ColorProvider from "./utils/globalTheme";
import { BrowserRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import pl from "date-fns/locale/pl";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
        <AuthManager>
          <ColorProvider>
            <App />
          </ColorProvider>
        </AuthManager>
      </LocalizationProvider>
    </BrowserRouter>
  </React.StrictMode>
);

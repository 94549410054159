import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
const AlertSnackbar = ({ onClick }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        variant="outlined"
        size="small"
        sx={{
          color: "white",
          borderColor: "white",
          "&:hover": {
            borderColor: "rgba(255,255,255,0.6)",
          },
        }}
        onClick={onClick}
      >
        TAK
      </Button>
      <Button
        color="secondary"
        size="small"
        sx={{ color: "white" }}
        onClick={handleClose}
      >
        Nie
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <IconButton onClick={handleClick}>
        <DeleteIcon sx={{ color: theme.palette.primary.alert }} />
      </IconButton>
      <Snackbar
        open={open}
        ContentProps={{
          sx: {
            backgroundColor: theme.palette.primary.alert,
            color: "white",
          },
        }}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Czy na pewno chcesz usunąć ten element?"
        action={action}
      />
    </div>
  );
};
export default AlertSnackbar;

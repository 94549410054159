import { api } from "../utils/AuthManager";
import startOfYear from "date-fns/startOfYear";
import format from "date-fns/format";
const GetPowerPeak = async (id, aggr, date) => {
  return api
    .get(`/api/power_peak/${parseInt(id)}/`, {
      params: {
        aggr: aggr,
        date: format(startOfYear(date), "yyyy-MM-dd"),
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPowerPeak;

import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import { useTheme } from "@emotion/react";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    theme,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={"rgba(255,255,255,0.9)"}
      >{`${(percent * 100).toFixed(2)}%`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {payload.type === "low"
          ? "Godziny pozaszczytowe"
          : payload.type === "high"
          ? "Godziny szczytowe"
          : payload.type === "flat"
          ? "Taryfa całodobowa"
          : payload.type === "high_morning"
          ? "Szczyt przedpołudniowy"
          : payload.type === "high_evening" && "Szczyt popołudniowy"}
      </text>
    </g>
  );
};

const ProfilePieChart = ({ width, height, dataset }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={dataset}
          cx={width / 2}
          cy={height / 2}
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {dataset.map((entry, index) => {
            return (
              <Cell
                theme={theme}
                key={`cell-${index}`}
                stroke="none"
                fill={
                  entry.type === "low"
                    ? theme.palette.primary.success
                    : entry.type === "high"
                    ? theme.palette.primary.alert
                    : entry.type === "flat"
                    ? theme.palette.primary.main
                    : (entry.type === "high_morning" ||
                        entry.type === "high_evening") &&
                      theme.palette.primary.alert
                }
              />
            );
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
export default ProfilePieChart;

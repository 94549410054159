import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, Divider, LinearProgress } from "@mui/material";
import WeekPicker from "../components/WeekPicker";
import GetTHD from "../api/GetTHD";
import GetPowerFactor from "../api/GetPowerFactor";
import THDChart from "../components/THDChart";
import { ParamsContext } from "./AnalyzePage";
const THD = () => {
  const { id, aggr } = useContext(ParamsContext);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [data, setData] = useState();
  const [arr, setArr] = useState();
  const [powerFactor, setPowerFactor] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    if (id && pickedDate) {
      setLoading(true);
      const timer = setTimeout(() => {
        GetTHD(id, pickedDate)
          .then((res) => {
            console.log(res);
            setData(res.data);
          })
          .catch((err) => console.log(err));
        GetPowerFactor(id, pickedDate)
          .then((res) => {
            console.log(res);
            setPowerFactor(res.data);
          })
          .catch((err) => console.log(err))
          .finally(setLoading(false));
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [id, pickedDate]);
  const handleChangeDay = (val) => {
    setPickedDate(val);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          maxHeight: "10rem",
          marginTop: "1rem",
        }}
      >
        <WeekPicker handleChangeDay={handleChangeDay} pickedDate={pickedDate} />
      </Box>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {aggr !== "meter" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "20rem",
              }}
            >
              <Typography variant="h3">
                Funkcjonalność dostępna tylko dla pojedynczego urządzenia.
              </Typography>{" "}
              <Typography variant="p">Wybierz urządzenie z listy</Typography>{" "}
            </Box>
          ) : (
            data &&
            data.volts.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40rem",
                  height: "40rem",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <Typography variant="h3">
                  Średnie odkształcenia napięciowe
                </Typography>
                <THDChart dataset={data.volts} />
              </Box>
            )
          )}
          <Divider sx={{ marginBottom: "1rem", marginTop: "1rem" }} />
          {data && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "40rem",
                height: "40rem",
              }}
            >
              <Typography variant="h3">
                Średnie odkształcenia prądowe
              </Typography>
              {data.current.length > 0 ? (
                <THDChart dataset={data.current} />
              ) : (
                id &&
                pickedDate && (
                  <Typography
                    variant="h2"
                    sx={{ textAlign: "center", opacity: 0.5 }}
                  >
                    Nie zarejestrowano odkształceń prądowych w zadanym okresie
                  </Typography>
                )
              )}
            </Box>
          )}
          <Divider sx={{ marginBottom: "1rem", marginTop: "1rem" }} />

          {powerFactor && powerFactor.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "40rem",
                height: "40rem",
              }}
            >
              <Typography variant="h3">Współczynnik mocy</Typography>
              <THDChart dataset={powerFactor} type="powerFactor" />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default THD;

import { api } from "../utils/AuthManager";
const AssingToPriceList = async (data) => {
  return api
    .post(`/api/assing_pricelist/`, data, {
      params: {
        aggr: "meter",
        operation: "add",
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default AssingToPriceList;

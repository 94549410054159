import { api } from "../utils/AuthManager";
import format from "date-fns/format";
import { startOfMonth, startOfYear } from "date-fns";
const GetProduction = async (id, date, type) => {
  console.log(date);
  return api
    .get(`/api/inverter_energy_data/${parseInt(id)}/`, {
      params: {
        date:
          type === "day"
            ? format(date, "yyyy-MM-dd")
            : type === "month"
            ? format(startOfMonth(date), "yyyy-MM-dd")
            : type === "year" && format(startOfYear(date), "yyyy-MM-dd"),
        date_aggr: type,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetProduction;

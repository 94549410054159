export const parseReactivePowers = (val) => {
    let positive = []
    let negative = []
    val.map((entry, index)=>{
    if (entry.y > 0){
      positive.push(entry)
    }
    if (entry.y < 0){
      negative.push({...entry, y:Math.abs(entry.y)})
    }
  })
  return [positive, negative]
}

export const mergedReactivePowers = (val) => {
    let positive = []
    val.map((entry, index)=>{
        if (entry) {

            if (entry.y >= 0){
                positive.push({x:entry.x, y0:Math.abs(entry.y)})
            }
            if (entry.y < 0){
                positive.push({x:entry.x, y1:Math.abs(entry.y)})
            }
        }
  })
  return positive
}
import React, { useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useState } from "react";
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiInputBase-input": {
    color: "#212528",
  },
  "& label.Mui-focused": {
    color: "#212528",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.25rem",
    color: "#171D22",
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.tertiary,
  },
}));
const AddDialog = ({
  open,
  handleClose,
  handlePostGroup,
  handlePatchGroup,
  type,
  mode,
  formData,
}) => {
  const theme = useTheme();
  const [data, setData] = useState({
    name: "",
    ordered_power: 0,
    anomaly_threshold_percentage: 0,
  });
  useEffect(() => {
    if (mode === "edit") {
      console.log(mode);
      console.log(formData);
      setData({
        ...data,
        name: formData.name,
        ordered_power: formData.ordered_power ? formData.ordered_power : 0,
        anomaly_threshold_percentage: formData.anomaly_threshold_percentage
          ? formData.anomaly_threshold_percentage
          : 0,
      });
    }
  }, [mode, formData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.text.tertiary }}>
        Dodaj
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          autoFocus
          margin="dense"
          id="name"
          label="Nazwa"
          type="text"
          fullWidth
          required
          variant="standard"
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />
        {type === "group" && (
          <StyledTextField
            autoFocus
            margin="dense"
            id="name"
            label="Moc zamówiona"
            type="number"
            fullWidth
            variant="standard"
            helperText="Jeśli chcesz liczyć moc zamówioną dla poszczególnych urządzeń oddzielnie, pozostaw to pole puste"
            value={data.ordered_power}
            onChange={(e) => {
              setData({ ...data, ordered_power: e.target.value });
            }}
          />
        )}
        {type === "group" && (
          <StyledTextField
            autoFocus
            margin="dense"
            id="name"
            label="Próg anomalii [%]"
            type="number"
            fullWidth
            variant="standard"
            helperText="Jeśli chcesz sprawdzać anomalie dla poszczególnych urządzeń oddzielnie, pozostaw to pole puste"
            value={data.anomaly_threshold_percentage}
            onChange={(e) => {
              setData({
                ...data,
                anomaly_threshold_percentage: e.target.value,
              });
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setData({
              name: "",
              ordered_power: 0,
              anomaly_threshold_percentage: 0,
            });
            handleClose();
          }}
        >
          Anuluj
        </Button>
        <Button
          onClick={() => {
            if (mode === "edit") {
              handlePatchGroup(formData.id, data);
            } else {
              handlePostGroup(data);
            }
            setData({
              name: "",
              ordered_power: 0,
              anomaly_threshold_percentage: 0,
            });
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDialog;

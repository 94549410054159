import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box/Box";
import { Divider, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import GetSummary from "../api/GetSummary";
import GetAllFacilities from "../api/GetAllFacilities";
import GetAllGroups from "../api/GetAllGroups";
import GetFavourite from "../api/GetFavourite";
import PatchMeter from "../api/PatchMeter";
import MainTable from "../components/MainTable";
import GetAllMeters from "../api/GetAllMeters";
import MultiSelector from "../components/MultiSelector";
import GetChecklist from "../api/GetChecklist";
import GetProfile from "../api/GetProfile";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
const Dashboard = () => {
  const [summary, setSummary] = useState();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allMeters, setAllMeters] = useState();
  const [id, setId] = useState();
  const [aggr, setAggr] = useState("");
  const [groups, setGroups] = useState();
  const [facilities, setFacilities] = useState();
  const [favourites, setFavourites] = useState();
  const [checklist, setChecklist] = useState();
  const [profile, setProfile] = useState();
  const [canOptimize, setCanOptimize] = useState(false);
  const [name, setName] = useState();
  const theme = useTheme();
  const handleGetUnits = (val) => {
    GetAllGroups()
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => console.log(err));

    GetAllMeters()
      .then((res) => {
        // console.log(res);
        setAllMeters(res.data);
        if (res.data.length === 1) {
          setId(res.data[0].id);
          setAggr("meter");
        }
      })
      .catch((err) => console.log(err));

    GetAllFacilities()
      .then((res) => {
        console.log(res.data);
        setFacilities(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    GetChecklist(id, aggr)
      .then((res) => {
        console.log(res);
        setChecklist(res.data);
      })
      .catch((err) => console.log(err));
    GetSummary()
      .then((res) => {
        console.log(res);
        setSummary(res.data);
      })
      .catch((err) => console.log(err));
    GetProfile(id, aggr)
      .then((res) => {
        console.log(res);
        setProfile(res.data);
        let user = res.data.user_tariff[0].total_cost;
        let optimizer = res.data.tariffs.find((e) => e.total_cost < user);
        console.log(optimizer);
        if (optimizer) {
          console.log(optimizer);
          setCanOptimize(true);
        } else {
          setCanOptimize(false);
        }
      })
      .catch((err) => console.log(err));
  }, [id, aggr]);
  useEffect(() => {
    handleGetUnits();
  }, [reload]);
  const handlePatchMeter = (id, val) => {
    PatchMeter(id, val)
      .then((res) => {
        setReload((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const handleSetAggr = (val) => {
    console.log(val);
    setAggr(val.type);
    setId(val.id);
    setName(val.name);
  };
  return (
    <React.Fragment>
      <Grid item lg={12} md={12} xs={12} sm={12}>
        <Box sx={{ flexShrink: "1" }}>
          <Typography variant="h1" sx={{ marginBottom: "2rem" }}>
            Twój system
          </Typography>

          <Grid container spacing={5}>
            <Grid item lg={4} md={12} sm={12} xs={12} sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: theme.palette.primary.surface,
                  border: "solid",
                  borderColor: theme.palette.primary.success,
                  borderRadius: "25px",
                  padding: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography variant="h2">Przegląd rekomendacji</Typography>
                <Divider
                  flexItem
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    padding: "0.1rem",
                  }}
                />
                {allMeters && allMeters.length > 1 && groups && facilities ? (
                  <>
                    {!id && (
                      <Typography variant="h3">Wybierz z listy</Typography>
                    )}
                    <MultiSelector
                      handleChange={handleSetAggr}
                      aggr={aggr}
                      id={id}
                      meters={allMeters}
                      facilities={facilities}
                      groups={groups}
                      name={name}
                    />
                  </>
                ) : (
                  allMeters &&
                  allMeters[0] && (
                    <Typography variant="h3">{allMeters[0].name}</Typography>
                  )
                )}
                {checklist && (
                  <>
                    <Divider flexItem />
                    {checklist.ordered_power !== 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h3">
                          Moc zamówiona{" "}
                          <span>
                            {(checklist.active_power_peak * 100) /
                              checklist.ordered_power <
                            80 ? (
                              <SentimentVeryDissatisfiedIcon
                                sx={{
                                  color: theme.palette.primary.alert,
                                }}
                              />
                            ) : (
                              <SentimentSatisfiedAltIcon
                                sx={{
                                  color: theme.palette.primary.success,
                                }}
                              />
                            )}
                          </span>
                        </Typography>
                        <Typography variant="p">
                          Szczyt mocy w dniu poprzednim wyniósł{" "}
                          {(checklist.active_power_peak / 1000).toFixed(0)}
                          &nbsp;kW, co stanowi tylko{" "}
                          {(
                            (checklist.active_power_peak * 100) /
                            checklist.ordered_power
                          ).toFixed(0)}
                          &nbsp;% Twojej mocy zamówionej.
                        </Typography>
                        {(checklist.active_power_peak * 100) /
                          checklist.ordered_power <
                          80 && (
                          <Typography
                            variant="p"
                            sx={{ color: theme.palette.primary.alert }}
                          >
                            Możliwa optymalizacja - obniżenie mocy zamówionej
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h3">
                          Moc zamówiona{" "}
                          <span>
                            <SentimentVeryDissatisfiedIcon
                              sx={{
                                color: theme.palette.primary.alert,
                              }}
                            />
                          </span>
                        </Typography>
                        <Typography variant="p">
                          Nie ustawiono mocy zamówionej w panelu "Konfiguracja"
                          dla wybranego elementu
                        </Typography>
                      </Box>
                    )}
                    <Divider flexItem />
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="h3">
                        Moc bierna{" "}
                        <span>
                          {checklist.reactive_power_peak < 0 ? (
                            <SentimentVeryDissatisfiedIcon
                              sx={{
                                color: theme.palette.primary.alert,
                              }}
                            />
                          ) : (
                            <SentimentSatisfiedAltIcon
                              sx={{
                                color: theme.palette.primary.success,
                              }}
                            />
                          )}
                        </span>
                      </Typography>
                      <Typography variant="p">
                        Szczyt poboru mocy biernej pojemnościowej w dniu
                        poprzednim wyniósł{" "}
                        {Math.abs(checklist.reactive_power_peak)} kVAr.
                      </Typography>
                      {checklist.reactive_power_peak < 0 ? (
                        <Typography
                          variant="p"
                          sx={{ color: theme.palette.primary.alert }}
                        >
                          Możliwa optymalizacja - kompensacja mocy biernej
                        </Typography>
                      ) : (
                        <Typography
                          variant="p"
                          sx={{ color: theme.palette.primary.success }}
                        >
                          Wszystko w porządku - nie masz problemów z mocą bierną
                          pojemnościową
                        </Typography>
                      )}

                      {/* <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.tertiary }}
                      >
                        {checklist.total_price}
                      </Typography> */}
                    </Box>
                    <Divider flexItem />
                    {profile && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h3">
                          Taryfa dystrybucyjna{" "}
                          <span>
                            {canOptimize ? (
                              <SentimentVeryDissatisfiedIcon
                                sx={{
                                  color: theme.palette.primary.alert,
                                }}
                              />
                            ) : (
                              <SentimentSatisfiedAltIcon
                                sx={{
                                  color: theme.palette.primary.success,
                                }}
                              />
                            )}
                          </span>
                        </Typography>
                        <Typography variant="p">
                          Koszt za dystrybucję energii w miesiącu poprzednim
                          wyniósł{" "}
                          <span style={{ color: theme.palette.primary.yellow }}>
                            {profile.user_tariff[0].total_cost.toFixed(2)} PLN.
                          </span>{" "}
                          {!canOptimize &&
                            `Żadna z dostępnych taryf Twojego operatora nie spowoduje obniżenia kosztów`}
                        </Typography>
                        {canOptimize ? (
                          profile.tariffs.length > 0 &&
                          profile.tariffs.map((entry, index) => {
                            if (
                              entry.total_cost <
                              profile.user_tariff[0].total_cost
                            ) {
                              return (
                                <Typography
                                  variant="p"
                                  sx={{ color: theme.palette.primary.alert }}
                                >
                                  Możliwa optymalizacja - zmiana taryfy na{" "}
                                  {entry.name}
                                </Typography>
                              );
                            }
                          })
                        ) : (
                          <Typography
                            variant="p"
                            sx={{ color: theme.palette.primary.success }}
                          >
                            Twoja taryfa jest optymalna.
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Divider />
                    <Typography variant="p">
                      Po szczegółowe informacje przejdź do zakładki Analiza
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "15rem",
                    background:
                      "linear-gradient(90.91deg, rgba(174, 61, 63, 0.87) 0.73%, rgba(174, 61, 63, 0.43) 99.19%)",
                    borderRadius: "25px",
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h1">Całkowite zużycie</Typography>
                    <Typography variant="p" sx={{ opacity: 0.6 }}>
                      W tym miesiącu
                    </Typography>
                    <Typography variant="h1" sx={{ fontSize: "3rem" }}>
                      {summary
                        ? summary.delta_sum
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : "---"}
                      <span style={{ fontSize: "0.5em" }}> kWh</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h1">Całkowity koszt</Typography>
                    <Typography variant="p" sx={{ opacity: 0.6 }}>
                      W tym miesiącu
                    </Typography>
                    <Typography variant="h1" sx={{ fontSize: "3rem" }}>
                      {summary
                        ? summary.cost_sum
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : "---"}
                      <span style={{ fontSize: "0.5em" }}> PLN</span>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", height: "100%", maxHeight: "24rem" }}>
                  <Typography variant="p">Bieżący stan urządzeń</Typography>
                  {allMeters && (
                    <MainTable
                      handlePatchMeter={handlePatchMeter}
                      data={allMeters}
                      loading={loading}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;

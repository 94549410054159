import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import GetAllMeters from "../api/GetAllMeters";
import PatchMeter from "../api/PatchMeter";
import GetAllGroups from "../api/GetAllGroups";
import MeterTable from "../components/MeterTable";
import GroupSettings from "../components/GroupSettings";
import GetAllFacilities from "../api/GetAllFacilities";
import DeleteStructure from "../api/DeleteStructure";
const StructureConfig = () => {
  const theme = useTheme();
  const [allMeters, setAllMeters] = useState();
  const [allGroups, setAllGroups] = useState();
  const [loading, setLoading] = useState(false);
  const [allFacilities, setAllFacilities] = useState();
  const [reload, setReload] = useState();
  const handleDeleteStructure = (val, val2) => {
    DeleteStructure(val, val2)
      .then((res) => {
        console.log(res);
        setReload((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const handlePatchMeter = (id, val) => {
    PatchMeter(id, val)
      .then((res) => {
        setReload((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setLoading(true);
    GetAllGroups()
      .then((res) => {
        setAllGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    GetAllMeters()
      .then((res) => {
        setAllMeters(res.data);
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
    GetAllFacilities()
      .then((res) => {
        setAllFacilities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);
  return (
    <React.Fragment>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <GroupSettings
          handleDeleteStructure={handleDeleteStructure}
          allFacilities={allFacilities}
          allGroups={allGroups}
          reload={reload}
          setReload={setReload}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ padding: "1rem" }}>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.5rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
        <Typography variant="h1" sx={{ padding: "1rem" }}>
          Urządzenia
        </Typography>
        <Typography variant="p" sx={{ color: "rgba(255,255,255,0.6)" }}>
          Kliknij dwukrotnie na komórkę, by ją edytować. Możesz wyłącznie
          edytować moc zamówioną, próg anomalii, cennik oraz przypisanie do
          grupy
        </Typography>
        <Box sx={{ height: "100%", width: "100%" }}>
          {allMeters && (
            <MeterTable
              data={allMeters}
              loading={loading}
              groups={allGroups}
              facilities={allFacilities}
              handlePatchMeter={handlePatchMeter}
            />
          )}
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default StructureConfig;

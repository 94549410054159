import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import AnomaliesDataGrid from "../components/AnomaliesDataGrid";
import GetAnomaly from "../api/GetAnomaly";
const Anomalies = () => {
  const [dataset, setDataset] = useState();
  const [loading, setLoading] = useState();
  useEffect(() => {
    setLoading(true);
    GetAnomaly()
      .then((res) => {
        setDataset(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <Box
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 5 }}
    >
      {loading ? (
        <CircularProgress
          size={100}
          sx={{ position: "absolute", top: "50%", left: "50%" }}
        />
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h1">Anomalie dla liczników</Typography>
            {dataset && (
              <AnomaliesDataGrid data={dataset.meters} type="meter" />
            )}
            <Typography variant="p" sx={{ opacity: 0.6 }}>
              System sprawdza średnią z minimum trzech odpowiadających sobie dni
              tygodnia. Jeśli poziom zużycia w danym dniu przekroczy próg
              odchylenia określony w zakładce "Konfiguracja", system
              zarejestruje anomalię.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h1">Anomalie dla grup</Typography>
            {dataset && (
              <AnomaliesDataGrid type="group" data={dataset.groups} />
            )}
            <Typography variant="p" sx={{ opacity: 0.6 }}>
              System sprawdza średnią z minimum trzech odpowiadających sobie dni
              tygodnia. Jeśli poziom zużycia w danym dniu przekroczy próg
              odchylenia określony w zakładce "Konfiguracja", system
              zarejestruje anomalię.
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Anomalies;

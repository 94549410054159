import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@emotion/react";
import { mergeSlotProps } from "@mui/base";

const TariffSelector = ({
  pickedGroup,
  handleChange,
  groups,
  group,
  label,
  onOpen,
  type,
}) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth sx={{ backgroundColor: theme.palette.primary.dark }}>
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          color: theme.palette.primary.surface,
          "&.Mui-focused": {
            color: theme.palette.primary.accentActive,
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={{
          ".MuiSelect-icon": {
            color: theme.palette.primary.surface,
          },
          color: theme.palette.primary.surface,
        }}
        value={pickedGroup}
        onOpen={onOpen}
        label={label}
        renderValue={(props) => props.tariff_name}
        variant="outlined"
        onChange={handleChange}
      >
        {groups.map((entry, index) => {
          console.log(entry);
          return (
            <MenuItem
              value={entry}
              key={entry.id}
              sx={{
                backgroundColor: group === entry.id && "rgba(0,0,0,0.1)",
              }}
            >
              {entry.tariff_name}
              {`-${entry.energy_vendor}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default TariffSelector;

import React, { useEffect, useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import GroupSelector from "./GroupSelector";
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { IconButton, Button } from "@mui/material";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.8) 100%)`,
  width: "100%",
  height: "100%",
  minHeight: "40rem",
  ".MuiDataGrid-columnHeaders": {
    // backgroundColor: theme.palette.primary.mainBackground,
    background: theme.palette.primary.accentActive,
    color: "white",
  },

  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },

  "& div div div div >.MuiDataGrid-cell:focus": {
    outline: "none",
  },

  "& div div div div >.MuiDataGrid-cell": {
    borderBottom: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
    borderRight: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
  },

  "	.MuiDataGrid-menuIconButton": {
    display: "none",
  },
}));

const MeterTable = ({ data, loading, handlePatchMeter, groups }) => {
  console.log(data);
  const theme = useTheme();
  const [pickedGroup, setPickedGroup] = useState("");
  const [selectedCellParams, setSelectedCellParams] = React.useState(null);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const cellMode = React.useMemo(() => {
    if (!selectedCellParams) {
      return "view";
    }
    const { id, field } = selectedCellParams;
    return cellModesModel[id]?.[field]?.mode || "view";
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = React.useCallback(
    (params, event) => {
      if (cellMode === "edit") {
        // Prevents calling event.preventDefault() if Tab is pressed on a cell in edit mode
        event.defaultMuiPrevented = true;
      }
    },
    [cellMode]
  );
  const handleChange = (event) => {
    setPickedGroup(event.target.value);
  };
  const columns = [
    // {
    //   field: "favourite",
    //   headerName: "",
    //   flex: 0.1,
    //   sortable: false,
    //   renderCell: (params) => {
    //     if (params.value === false) {
    //       return (
    //         <IconButton
    //           onClick={() => {
    //             handlePatchMeter(params.row.id, { favourite: true });
    //           }}
    //         >
    //           <StarBorderIcon />
    //         </IconButton>
    //       );
    //     } else {
    //       return (
    //         <IconButton
    //           onClick={() => {
    //             handlePatchMeter(params.row.id, { favourite: false });
    //           }}
    //         >
    //           <StarIcon sx={{ color: theme.palette.primary.accentActive }} />
    //         </IconButton>
    //       );
    //     }
    //   },
    // },
    {
      field: "name",
      headerName: "Nazwa",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "serial_number",
      headerName: "Numer seryjny",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "ordered_power",
      headerName: "Moc zamówiona",
      flex: 0.7,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        if (params.value === null) {
          return 0;
        } else {
          return params.value;
        }
      },
    },
    {
      field: "anomaly_threshold_percentage",
      headerName: "Próg anomalii [%]",
      flex: 0.7,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        if (params.value === null) {
          return 0;
        } else {
          return params.value;
        }
      },
    },
    {
      field: "current_pricelist_tariff",
      headerName: "Cennik",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "group",
      headerName: "Grupa",
      flex: 0.7,
      sortable: false,
      editable: true,
      renderEditCell: (params) => {
        return (
          <GroupSelector
            label="Wybierz nową"
            group={params.row.group}
            groups={groups}
            handleChange={handleChange}
            pickedGroup={pickedGroup}
          />
        );
      },
      renderCell: (params) => {
        return params.row.group_name;
      },
    },
  ];

  return (
    <>
      <CustomDataGrid
        rows={data}
        {...data}
        loading={loading}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        checkboxSelection={false}
        // disableSelectionOnClick
        ColumnMenuIcon={false}
        onCellKeyDown={handleCellKeyDown}
        sx={{
          "& .MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          },
        }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={(model) => setCellModesModel(model)}
        experimentalFeatures={{ newEditingApi: true }}
        onCellEditStop={(params, event) => {
          if (event.key === "Escape") {
            return;
          } else {
            if (params.field === "group") {
              handlePatchMeter(params.row.id, {
                group: pickedGroup,
              });
            } else {
              handlePatchMeter(params.row.id, {
                [params.field]: event.target.value,
              });
            }
          }
        }}
      />
    </>
  );
};

export default MeterTable;

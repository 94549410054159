import { api } from "../utils/AuthManager";
import startOfWeek from "date-fns/startOfWeek";
import format from "date-fns/format";
const GetPower = async (id, aggr, type, date) => {
  return api
    .get(`/api/power_data/${parseInt(id)}`, {
      params: {
        aggr: aggr,
        date: format(startOfWeek(date), "yyyy-MM-dd"),
        power_type: type,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPower;

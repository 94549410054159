import React from "react";
import { useTheme } from "@emotion/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  ComposedChart,
  Cell,
} from "recharts";
import format from "date-fns/format";
import { Box, Typography } from "@mui/material";
import { pl } from "date-fns/locale";

const CustomTooltip = ({ active, payload, label, aggrType }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    //
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.surface,
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">Dzień</Typography>
          <Typography variant="p">
            {aggrType === "day"
              ? format(new Date(label), "HH:mm")
              : aggrType === "month"
              ? format(new Date(label), "eeee dd/MM/yyyy", { locale: pl })
              : aggrType === "year" &&
                format(new Date(label), "MMM/yyyy", { locale: pl })}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3">Produkcja energii</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="p">
              {(payload[0].value / 1000).toFixed(0)}
            </Typography>
            <Typography
              variant="p"
              sx={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}
            >
              {payload[0].unit}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};
const ProductionChart = ({ data, aggrType }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer>
      <BarChart
        width={730}
        height={250}
        data={data.dataset}
        margin={{
          top: 50,
          right: 50,
          left: 50,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          label={{
            fill: theme.palette.text.secondary,
            value:
              aggrType === "day"
                ? "Interwał 15 minut"
                : aggrType === "month"
                ? "Dzień"
                : aggrType === "year" && "Miesiąc",
            angle: 0,
            fontSize: "15",
            offset: "5",
            fontWeight: "bold",
            position: "bottom",
          }}
          tickFormatter={(tick) => {
            if (aggrType === "day") {
              return format(tick, "HH:mm");
            }
            if (aggrType === "month") {
              return format(tick, "dd");
            }
            if (aggrType === "year") {
              return format(tick, "yyyy");
            }
          }}
        />
        <YAxis
          tickFormatter={(tick) => {
            return (tick / 1000).toFixed(0);
          }}
          label={{
            fill: theme.palette.text.secondary,
            value: "Produkcja [kWh]",
            angle: 0,
            fontSize: "15",
            offset: "15",
            fontWeight: "bold",
            position: "top",
          }}
        />
        <Bar dataKey="y" unit="kWh" fill={theme.palette.primary.success} />
        <Tooltip
          content={<CustomTooltip aggrType={aggrType} />}
          cursor={{ fill: "transparent" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ProductionChart;

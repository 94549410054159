import React, { useContext } from "react";
import { AuthContext } from "../utils/AuthManager";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Drawer, Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PowerIcon from "@mui/icons-material/Power";
import PowerInputIcon from "@mui/icons-material/PowerInput";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import HomeIcon from "@mui/icons-material/Home";
import ErrorIcon from "@mui/icons-material/Error";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: "25%",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: `${(1 / 6) * 100}%`,
    boxSizing: "border-box",
    background: theme.palette.primary.dark,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    // paddingTop: "-25px",
    // gap: "4rem",
  },
  // backgroundColor: "#005D8A",
  // backgroundColor: theme.palette.primary.mainBackground,
}));

const StyledText = styled(Typography)(({ theme, active }) => ({
  fontSize: "1.25rem",
  fontWeight: "bold",
  fontFamily: ["Rubik, sans-serif"].join(","),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "&:hover": {
    color: active
      ? theme.palette.text.primary
      : theme.palette.primary.accentActive,
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  justifyContent: "flex-start",
  textTransform: "none",
  fontSize: "1rem",
  fontFamily: ["Rubik, sans-serif"].join(","),
  color: theme.palette.text.tertiary,
  "&:hover": {
    color: "#3F9EFB",
    background: "none",
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const { logout, username } = useContext(AuthContext);
  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          //   gap: "10px",
          // padding: "0px 3rem",
        }}
      >
        <img
          src={theme.palette.primary.logo}
          style={{ width: "8rem", padding: "0.5rem" }}
          alt="Logo"
        />
        <Typography variant="h3" sx={{ color: theme.palette.primary.surface }}>
          {username}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          //   width: "100%",
          gap: "1rem",
          paddingLeft: "2rem",
        }}
      >
        <NavLink
          to="/"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <HomeIcon sx={{ marginRight: "1rem" }} />
            Ekran główny
          </StyledText>
        </NavLink>
        <NavLink
          to="/rekomendacje"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <ReviewsIcon sx={{ marginRight: "1rem" }} />
            Rekomendacje
          </StyledText>
        </NavLink>
        <NavLink
          to="/analiza"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <LeaderboardIcon sx={{ marginRight: "1rem" }} />
            Analiza
          </StyledText>
        </NavLink>

        <NavLink
          to="/prosument"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <SolarPowerIcon sx={{ marginRight: "1rem" }} />
            Panel prosumenta
          </StyledText>
        </NavLink>

        <NavLink
          to="/anomalie"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <ErrorIcon sx={{ marginRight: "1rem" }} />
            Anomalie
          </StyledText>
        </NavLink>
        <NavLink
          to="/raporty"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <SummarizeIcon sx={{ marginRight: "1rem" }} />
            Raporty
          </StyledText>
        </NavLink>
        <NavLink
          to="/konfiguracja"
          end
          style={({ isActive }) => ({
            color: isActive
              ? theme.palette.primary.accentActive
              : theme.palette.text.tertiary,
            textDecoration: "none",
          })}
        >
          <StyledText>
            <DisplaySettingsIcon sx={{ marginRight: "1rem" }} />
            Konfiguracja
          </StyledText>
        </NavLink>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   alignSelf: "flex-end",
          // padding: "0px 3rem",
        }}
      >
        <LogoutButton onClick={logout} endIcon={<LogoutIcon />}>
          Wyloguj
        </LogoutButton>
        {/* <CustomSwitch /> */}
      </Box>
    </StyledDrawer>
  );
};

export default Navbar;

import React, { useEffect, useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import GroupSelector from "./GroupSelector";
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { IconButton, Button } from "@mui/material";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.8) 100%)`,
  width: "100%",
  height: "100%",
  padding: "1rem",
  minHeight: "25rem",
  ".MuiDataGrid-columnHeaders": {
    ".MuiDataGrid-iconButtonContainer": {
      visibility: "visible",
    },
    // backgroundColor: theme.palette.primary.mainBackground,
    background: theme.palette.primary.accentActive,
    ":not(.MuiDataGrid-columnHeader--sorted)": {
      ".MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "rgba(255,255,255,0.4)",
      },
    },
    ".MuiDataGrid-columnHeader--sorted": {
      ".MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "rgba(255,255,255,1)",
      },
    },
  },

  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },

  "& div div div div >.MuiDataGrid-cell:focus": {
    outline: "none",
  },

  "& div div div div >.MuiDataGrid-cell": {
    borderBottom: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
    borderRight: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
  },

  "	.MuiDataGrid-menuIconButton": {
    display: "none",
  },
}));

const MainTable = ({ data, loading, handlePatchMeter }) => {
  const theme = useTheme();
  const columns = [
    // {
    //   field: "favourite",
    //   headerName: "",
    //   flex: 0.1,
    //   sortable: true,
    //   renderCell: (params) => {
    //     if (params.value === false) {
    //       return (
    //         <IconButton
    //           onClick={() => {
    //             handlePatchMeter(params.row.id, { favourite: true });
    //           }}
    //         >
    //           <StarBorderIcon />
    //         </IconButton>
    //       );
    //     } else {
    //       return (
    //         <IconButton
    //           onClick={() => {
    //             handlePatchMeter(params.row.id, { favourite: false });
    //           }}
    //         >
    //           <StarIcon sx={{ color: theme.palette.primary.yellow }} />
    //         </IconButton>
    //       );
    //     }
    //   },
    // },
    {
      field: "name",
      headerName: "Nazwa",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "serial_number",
      headerName: "Numer seryjny",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "last_active_power",
      headerName: "Moc czynna chwilowa",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        if (params.value === null) {
          return 0;
        } else {
          return `${params.value} W`;
        }
      },
    },
    {
      field: "last_phase_a",
      headerName: "Napięcia fazowe",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        if (params.value === null) {
          return 0;
        } else {
          return `${params.row.last_phase_a}/${params.row.last_phase_b}/${params.row.last_phase_c} V`;
        }
      },
    },
    // {
    //   field: "ordered_power",
    //   headerName: "Moc zamówiona",
    //   flex: 0.7,
    //   sortable: false,
    //   renderCell: (params) => {
    //     if (params.value === null) {
    //       return 0;
    //     } else {
    //       return `${params.value} W`;
    //     }
    //   },
    // },
    {
      field: "total_energy",
      headerName: "Stan licznika",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        if (params.value === null) {
          return 0;
        } else {
          return `${(params.value / 1000).toFixed(0)} kWh`;
        }
      },
    },
    {
      field: "group_name",
      headerName: "Grupa",
      flex: 0.7,
      sortable: true,
    },
  ];

  return (
    <>
      <CustomDataGrid
        rows={data}
        {...data}
        loading={loading}
        columns={columns}
        pageSize={15}
        initialState={{
          sorting: {
            sortModel: [{ field: "favourite", sort: "desc" }],
          },
        }}
        rowsPerPageOptions={[15]}
        checkboxSelection={false}
        disableSelectionOnClick
        ColumnMenuIcon={false}
        sx={{
          "& .MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </>
  );
};

export default MainTable;

import React, { useState, useReducer, createContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { url } from "./urls";
import GetToken from "../api/GetToken";

const cookies = new Cookies();
export const AuthContext = createContext();
export const api = axios.create({
  baseURL: `${url}`,
});

export const AuthManager = ({ children }) => {
  const navigate = useNavigate();
  const test = 1;
  const [username, setUsername] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "SIGN_IN":
          return {
            ...prevState,
            isLogged: true,
            token: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLogged: false,
            token: null,
          };
        default:
          return { ...prevState };
      }
    },
    {
      isLogged: false,
      token: null,
    }
  );

  const login = (username, password) => {
    setLoading(true);
    setError("");
    GetToken(username, password)
      .then((res) => {
        setError("");
        setLoading(false);
        setUsername(username);
        if (res && res.status === 200) {
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 14);
          cookies.set("token", res.data.token, {
            path: "/",
            expires: tomorrow,
          });
          dispatch({ type: "SIGN_IN", token: res.data.token });
          navigate("/");
        }
      })
      .catch((err) => {
        setError(err.response);
        setLoading(false);
        dispatch({ type: "SIGN_OUT", token: null });
        return Promise.reject(err);
      });
  };

  const restore = () => {
    const token = cookies.get("token");
    if (token) {
      dispatch({ type: "SIGN_IN", token: token });
    }
  };

  const logout = () => {
    cookies.remove("token", { path: "/" });
    dispatch({ type: "SIGN_OUT" });
  };

  api.interceptors.request.use(
    async (config) => {
      const token = cookies.get("token");
      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
          logout();
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        test,
        state,
        login,
        restore,
        logout,
        username,
        setUsername,
        error,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

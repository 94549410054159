import { api } from "../utils/AuthManager";
const PostFacility = async (data) => {
  return api
    .post(`/api/facility/`, data)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default PostFacility;

import React, { useState, useEffect } from "react";
import RadioButtons from "../components/RadioButtons";
import TariffDatePicker from "../components/TariffDatePicker";
import format from "date-fns/format";
import { Box } from "@mui/system";
import GetAllGroups from "../api/GetAllGroups";
import GetAllMeters from "../api/GetAllMeters";
import GroupSelector from "../components/GroupSelector";
import GetAllFacilities from "../api/GetAllFacilities";
import ControlledCheckbox from "../components/ControlledCheckbox";
import { useTheme } from "@emotion/react";
import { Button, CircularProgress, Typography } from "@mui/material";
import GetReport from "../api/GetReport";
import { CSVLink } from "react-csv";
const Reactive = () => {
  const [id, setId] = useState();
  const [arr, setArr] = useState();
  const [aggr, setAggr] = useState("");
  const [startDate, setStartDate] = useState();
  const [data, setData] = useState();
  const [endDate, setEndDate] = useState();
  const [reportData, setReportData] = useState();
  const [type, setType] = useState();
  const theme = useTheme();
  const [name, setName] = useState();
  const [unit, setUnit] = useState("0");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    energy: false,
    power: false,
    anomaly: false,
  });
  const handleChangeUnit = (e) => {
    console.log(e);
    setUnit(e);
  };

  const handleChangeChecked = (event) => {
    // setLoading(true);
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };
  const handleGetReportData = () => {
    setLoading(true);
    setReportData("");
    GetReport(
      id,
      aggr,
      checked.anomaly,
      endDate,
      checked.energy,
      checked.power,
      startDate,
      type,
      unit
    )
      .then((res) => {
        if (type === "pdf") {
          let file = new Blob(["\ufeff", res.data], {
            type: "application/pdf",
          });
          setReportData(file);
        } else {
          console.log(res);
          setReportData(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (event, val) => {
    setId(event.target.value);
    setName(val.props.children);
  };
  const handleGetUnits = (val) => {
    console.log(typeof val);
    if (val === "group") {
      GetAllGroups()
        .then((res) => {
          console.log(res);
          setArr(res.data);
        })
        .catch((err) => console.log(err));
    }
    if (val === "meter") {
      GetAllMeters()
        .then((res) => {
          console.log(res);
          setArr(res.data);
        })
        .catch((err) => console.log(err));
    }
    if (val === "facility") {
      GetAllFacilities()
        .then((res) => {
          console.log(res);
          setArr(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  const handleChangeAggr = (val) => {
    setId(null);
    handleGetUnits(val);
    setAggr(val);
  };
  // useEffect(() => {
  //   const handleGetPower = () => {
  //     GetPower(id, aggr, "reactive", pickedDate)
  //       .then((res) => {
  //         console.log(res.data);
  //         let parsedData = parseReactivePowers(res.data);
  //         let positives = parsedData[0].sort((a, b) => {
  //           return a.y - b.y;
  //         });
  //         let negatives = parsedData[1].sort((a, b) => {
  //           return a.y - b.y;
  //         });
  //         let min = negatives[negatives.length - 1];
  //         let max = positives[positives.length - 1];
  //         setMaxValueNegative(min ? min.y : "");
  //         setMaxValuePositive(max ? max.y : "");
  //         setDataset(res.data);
  //         // setEnergyData(responseEnergy.data)
  //         // setChartLoading(false);
  //       })
  //       .catch((err) => console.log(err));
  //   };
  //   handleGetPower();
  // }, [pickedDate, id]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            // width: "100%",
          }}
        >
          <RadioButtons
            handleChangeAggr={handleChangeAggr}
            aggr={aggr}
            label="Stopień szczegółowości"
            arr={[
              { name: "Obiekt", value: "facility" },
              { name: "Grupa", value: "group" },
              { name: "Urządzenie", value: "meter" },
            ]}
          />
          {arr && arr.length > 0 && (
            <GroupSelector
              label="Wybierz"
              groups={arr}
              handleChange={handleChange}
              pickedGroup={id}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // width: "100%",
              marginTop: "1rem",
              justifyContent: "center",
              gap: 5,
              padding: "1rem",
              backgroundColor: theme.palette.primary.dark,
              borderRadius: "5px",
            }}
          >
            <TariffDatePicker
              setData={(val) =>
                setData({
                  ...data,
                  start_date: format(val, "yyyy-MM-dd"),
                })
              }
              startDate={startDate}
              setStartDate={setStartDate}
              label="Data rozpoczęcia"
            />
            <TariffDatePicker
              setData={(val) =>
                setData({ ...data, end_date: format(val, "yyyy-MM-dd") })
              }
              startDate={endDate}
              setStartDate={setEndDate}
              label="Data zakończenia"
            />
          </Box>
        </Box>
        <ControlledCheckbox
          unit={unit}
          handleChangeUnit={handleChangeUnit}
          handleChange={handleChangeChecked}
          checked={checked}
        />
        <RadioButtons
          handleChangeAggr={(val) => {
            setReportData("");
            setType(val);
          }}
          aggr={type}
          label="Format raportu"
          arr={[
            { name: "PDF", value: "pdf" },
            { name: "CSV", value: "csv" },
          ]}
        />
        <Button
          onClick={handleGetReportData}
          disabled={loading}
          sx={{
            color: "rgba(255,255,255,0.9)",
            backgroundColor: theme.palette.primary.accentActive,
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.palette.primary.accent,
            },
          }}
        >
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: "rgba(255,255,255,0.9)",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          Generuj raport
        </Button>

        {reportData && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h2">Twój raport jest gotowy</Typography>
            {type === "csv" ? (
              <CSVLink
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  background: "transparent",
                  borderRadius: "5px",
                  fontSize: "0.875rem",
                  border: "1px solid",
                  borderColor: theme.palette.primary.accentActive,
                  lineHeight: 1.75,
                  textTransform: "uppercase",
                  minWidth: "64px",
                  padding: "5px 15px",
                  borderColor: theme.palette.primary.accentActive,
                  fontFamily: ["Rubik, sans-serif"].join(","),
                  color: theme.palette.primary.accentActive,
                  fontWeight: "bold",
                  "&:hover": {
                    background: theme.palette.primary.surface,
                    color: theme.palette.text.primary,
                    border: "solid",
                    borderWidth: "2px",
                    borderColor: theme.palette.primary.accent,
                  },
                }}
                data={reportData}
              >
                Pobierz
              </CSVLink>
            ) : (
              type === "pdf" && (
                <Button
                  onClick={() => {
                    let fileURL = URL.createObjectURL(reportData);
                    let alink = document.createElement("a");

                    alink.href = fileURL;
                    alink.download = `${name}_${format(
                      startDate,
                      "yyyy-MM-dd"
                    )}-${format(endDate, "yyyy-MM-dd")}`;
                    alink.click();
                  }}
                  variant="outlined"
                  sx={{
                    color: theme.palette.primary.accentActive,
                    borderColor: theme.palette.primary.accentActive,
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: theme.palette.primary.accent,
                    },
                  }}
                >
                  Pobierz
                </Button>
              )
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Reactive;

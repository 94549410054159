import axios from "axios";
import { url } from "../utils/urls";

const GetToken = async (username, password) => {
  return axios
    .post(`${url}/api/login/`, {
      username: username,
      password: password,
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetToken;

import { useState, forwardRef, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import RadioButtons from "./RadioButtons";
import FlexibleDatePicker from "./FlexibleDatePicker";
import ProductionChart from "./ProductionChart";
import GetProduction from "../api/GetProduction";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductionDialog = ({ open, handleClose, pickedPV }) => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const [aggrType, setAggrType] = useState("day");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const handleChangeAggrType = (val) => {
    setAggrType(val);
  };

  const handleChangeDay = (val) => {
    setPickedDate(val);
  };
  useEffect(() => {
    GetProduction(pickedPV.id, pickedDate, aggrType)
      .then((res) => {
        setData(res.data);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, [pickedDate, pickedPV, aggrType]);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {pickedPV.name}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List>
        <ListItem>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <RadioButtons
              label="Typ wykresu"
              handleChangeAggr={handleChangeAggrType}
              aggr={aggrType}
              arr={[
                { name: "Dzienny", value: "day" },
                { name: "Miesięczny", value: "month" },
                { name: "Roczny", value: "year" },
              ]}
            />
            {aggrType === "day" && (
              <FlexibleDatePicker
                pickedDate={pickedDate}
                handleChangeDate={handleChangeDay}
                label={"Dzień"}
                views={["day"]}
                type="days"
                inpFormat={"dd. MM. yyyy"}
              />
            )}
            {aggrType === "month" && (
              <FlexibleDatePicker
                pickedDate={pickedDate}
                handleChangeDate={handleChangeDay}
                label={"Miesiąc"}
                views={["month"]}
                type="months"
                inpFormat={"MMM. yyyy"}
              />
            )}
            {aggrType === "year" && (
              <FlexibleDatePicker
                pickedDate={pickedDate}
                handleChangeDate={handleChangeDay}
                label={"Rok"}
                views={["year"]}
                type="years"
                inpFormat={"yyyy"}
              />
            )}
          </Box>
        </ListItem>
        <Divider />
        <ListItem>
          {data && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="h3">Suma produkcji:</Typography>
              <Typography variant="h3">
                {data.energy_total / 1000} kWh
              </Typography>
            </Box>
          )}
        </ListItem>
        <ListItem>
          <Box sx={{ width: "100%", height: "40rem" }}>
            {loading ? (
              <CircularProgress
                size={100}
                sx={{ position: "relative", top: "0", left: "50%" }}
              />
            ) : (
              data &&
              data.dataset.length > 0 && (
                <ProductionChart aggrType={aggrType} data={data} />
              )
            )}
          </Box>
        </ListItem>
      </List>
    </Dialog>
  );
};
export default ProductionDialog;

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import logo from "../utils/powerstream-logo.png";
import { AuthContext } from "../utils/AuthManager";
import { useContext } from "react";
const cookies = new Cookies();
const StyledGridItem = styled(Grid)(({ theme, alignItems, flexDirection }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: flexDirection,
  padding: "1rem",
  alignItems: alignItems,
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  minHeight: "100vh",
  width: "100vw",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  margin: 0,
  padding: 0,
  backgroundColor: "#ffff",
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "21.25rem",
  height: "27.5rem",
}));
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#171D22",
  borderRadius: "25px",
  paddingTop: "0.75rem",
  paddingBottom: "0.75rem",
  paddingLeft: "2.5rem",
  paddingRight: "2.5rem",
  fontSize: "0.875",
  fontFamily: ["Rubik, sans-serif"].join(","),
  color: "white",
  fontWeight: "bold",
  ":hover": {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiInputBase-input": {
    color: "#fff",
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.25rem",
    color: "#fff",
    fontFamily: ["Rubik, sans-serif"].join(","),

    //   '&:hover fieldset': {
    //     borderBottomColor: 'red',
    //   },
    //   '&.Mui-focused fieldset': {
    //     borderBottomColor: 'yellow',
    //   },
  },
}));

const LoginPage = () => {
  const theme = useTheme();
  const [password, setPassword] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState("");
  const [username, setUserName] = React.useState("");
  const [authFailCode, setAuthFailCode] = React.useState(0);
  const [remember, setRemember] = React.useState(false);
  const navigate = useNavigate();
  const { login, error, loading } = useContext(AuthContext);
  const { state } = useLocation();
  console.log(error);
  const handleLogin = async () => {
    setAuthFailCode(0);
    login(username, password).then((res) => {
      // navigate(state?.path || "/");
    });
  };
  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      // enter keyCode
      handleLogin();
    }
  };
  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };
  return (
    <StyledGridContainer container spacing={2}>
      <StyledGridItem
        item
        lg={12}
        md={12}
        xs={12}
        flexDirection="row"
        alignItems="baseline"
      >
        <img style={{ width: "12rem" }} src={logo} alt="Logo" />
      </StyledGridItem>
      <StyledGridItem item flexDirection="column" lg={12} md={12} xs={12}>
        <StyledPaper
          component="form"
          sx={{ backgroundColor: "#1D64A2" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <ThemeProvider theme={theme}>
            <Typography variant="h4" sx={{ color: "#ffff" }}>
              Zaloguj się
            </Typography>
          </ThemeProvider>

          <StyledTextField
            type="text"
            id="standard-basic-1"
            required={true}
            label="Email"
            value={username}
            variant="standard"
            onKeyDown={handleEnterPressed}
            onChange={(e) => setUserName(e.target.value)}
          />
          <StyledTextField
            type="password"
            id="standard-basic-1"
            required={true}
            label="Hasło"
            value={password}
            onKeyDown={handleEnterPressed}
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            sx={{ alignSelf: "center" }}
            control={
              <Checkbox
                sx={{ color: "black" }}
                checked={remember}
                onChange={handleRememberChange}
              />
            }
            label={
              <Typography
                variant="p"
                sx={{
                  color: "#fff",
                  "&:hover": {
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                Zapamiętaj mnie na 14 dni
              </Typography>
            }
          />
          <StyledButton type="submit">
            Zaloguj
            {loading && (
              <CircularProgress
                size={30}
                sx={{
                  color: theme.palette.primary.accentActive,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </StyledButton>

          {!error || error.status === 0 ? (
            <></>
          ) : error.status === 400 ? (
            <span style={{ color: "red" }}>Nieprawidłowe dane logowania</span>
          ) : error.status >= 500 && error.status < 600 ? (
            <span style={{ color: "red" }}>Błąd po stronie serwera</span>
          ) : (
            <span style={{ color: "red" }}>Niestandardowy błąd logowania</span>
          )}
        </StyledPaper>
      </StyledGridItem>
    </StyledGridContainer>
  );
};

export default LoginPage;

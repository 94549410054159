import React, { useEffect, useState } from "react";
import GetPvDashboard from "../api/GetPvDashboard";
import { Box } from "@mui/system";
import { Grid, Typography, Divider, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import ProductionDialog from "../components/ProductionDialog";
const PV = () => {
  const theme = useTheme();
  const [dashboard, setDashboard] = useState();
  const [open, setOpen] = useState(false);
  const [pickedPV, setPickedPV] = useState();

  const handleClickOpen = (val) => {
    setOpen(true);
    setPickedPV(val);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    GetPvDashboard()
      .then((res) => {
        setDashboard(res.data);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxHeight: "10rem",
        }}
      >
        <Typography variant="h1">Twoje falowniki</Typography>
      </Box>

      <Grid container>
        {dashboard &&
          dashboard.map((entry, index) => {
            return (
              <Grid
                key={index}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                sx={{ padding: "1rem" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    backgroundColor: theme.palette.primary.surface,
                    borderRadius: "25px",
                    padding: "2rem",
                    minHeight: "14rem",
                    width: "100%",
                  }}
                >
                  <Typography variant="h2">{entry.name}</Typography>
                  {entry.facility_name ||
                    (entry.group_name && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="p">Przypisany do:</Typography>
                        <Typography variant="h3">
                          {entry.facility_name}
                        </Typography>
                      </Box>
                    ))}
                  <Divider sx={{ margin: "0.2rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="p">Moc falownika: </Typography>
                    <Typography variant="h3">
                      {entry.inverter_rated_power} kW
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: "0.2rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="p">Moc generatora: </Typography>
                    <Typography variant="h3">
                      {entry.generator_rated_power} kWp
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: "0.2rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="p">Stan licznika: </Typography>
                    <Typography variant="h3">
                      {(entry.total_energy / 1000).toFixed(0)} kWh
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: "0.2rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="p">Moc czynna chwilowa: </Typography>
                    <Typography variant="h3">
                      {entry.last_active_power} W
                    </Typography>
                  </Box>
                  <Divider sx={{ margin: "0.2rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gap: 1,
                    }}
                  >
                    {/* <Button
                      // startIcon={<FactoryIcon />}
                      // onClick={() => handleClickOpen("facility")}
                      variant="outlined"
                      sx={{
                        color: theme.palette.primary.accentActive,
                        borderColor: theme.palette.primary.accentActive,
                        fontWeight: "bold",
                        "&:hover": {
                          borderColor: theme.palette.primary.accent,
                        },
                      }}
                    >
                      Edytuj
                    </Button> */}
                    <Button
                      // startIcon={<FactoryIcon />}
                      onClick={() => handleClickOpen(entry)}
                      variant="outlined"
                      sx={{
                        color: "rgba(255,255,255,0.9)",
                        backgroundColor: theme.palette.primary.accentActive,
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.accent,
                        },
                      }}
                    >
                      Zobacz produkcję
                    </Button>
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>
      {pickedPV && (
        <ProductionDialog
          open={open}
          handleClose={handleClose}
          pickedPV={pickedPV}
        />
      )}
    </>
  );
};

export default PV;

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AppBar } from "@mui/material";
import Energy from "../pages/Energy";
import Reactive from "../pages/Reactive";
import ActivePower from "./ActivePower";
import PowerPeak from "./PowerPeak";
import THD from "../pages/THD";
import { useTheme } from "@mui/material";
import Profile from "../pages/Profile";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RecommendationTabs = ({ meterPickerBox, id }) => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <AppBar position="static">
          {meterPickerBox}
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Moc zamówiona" {...a11yProps(0)} />
            <Tab label="Moc bierna" {...a11yProps(1)} />
            <Tab label="Profil zużycia" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        <PowerPeak />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Reactive />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Profile />
      </TabPanel>
    </Box>
  );
};
export default RecommendationTabs;

import React, { useState, useEffect, useContext } from "react";
import WeekPicker from "../components/WeekPicker";
import { Box } from "@mui/system";
import ReactivePowerChart from "../components/ReactivePowerChart";
import GetPower from "../api/GetPower";
import { parseReactivePowers } from "../utils/parseReactivePowers";
import { LinearProgress } from "@mui/material";
import { ParamsContext } from "./Recommendations";
import { useTheme } from "@emotion/react";
const Reactive = () => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const { id, aggr } = useContext(ParamsContext);
  const [dataset, setDataset] = useState();
  const [loading, setLoading] = useState();
  const [maxValueNegative, setMaxValueNegative] = useState(0);
  const [maxValuePositive, setMaxValuePositive] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      GetPower(id, aggr, "reactive", pickedDate)
        .then((res) => {
          console.log(res.data);
          let parsedData = parseReactivePowers(res.data);
          let positives = parsedData[0].sort((a, b) => {
            return a.y - b.y;
          });
          let negatives = parsedData[1].sort((a, b) => {
            return a.y - b.y;
          });
          let min = negatives[negatives.length - 1];
          let max = positives[positives.length - 1];
          setMaxValueNegative(min ? min.y : "");
          setMaxValuePositive(max ? max.y : "");
          setDataset(res.data);
          // setEnergyData(responseEnergy.data)
          // setChartLoading(false);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }, 500);

    return () => clearTimeout(timer);
  }, [pickedDate, id]);

  const handleChangeDay = (val) => {
    setPickedDate(val);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          maxHeight: "10rem",
        }}
      >
        <WeekPicker handleChangeDay={handleChangeDay} pickedDate={pickedDate} />
      </Box>

      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "35rem",
          }}
        >
          {dataset && (
            <ReactivePowerChart
              maxValuePositive={maxValuePositive}
              maxValueNegative={maxValueNegative}
              dataset={dataset}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Reactive;

import { api } from "../utils/AuthManager";
const DeleteStructure = async (url, id) => {
  return api
    .delete(`/api/${url}/${id}/`)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default DeleteStructure;

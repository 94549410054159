import { api } from "../utils/AuthManager";
const PostPriceList = async (data) => {
  return api
    .post(`/api/pricelist/`, data)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default PostPriceList;

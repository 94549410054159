import React, { useState, useEffect, useContext } from "react";
import OrderedPowerPanel from "./OrderedPowerPanel";
import { Box } from "@mui/system";
import { Grid, LinearProgress } from "@mui/material";
import { ParamsContext } from "../pages/Recommendations";
import GetPowerPeak from "../api/GetPowerPeak";
import MultiDatePicker from "./MultiDatePicker";
import PowerPeakChart from "./PowerPeakChart";

const PowerPeak = () => {
  const [pickedDatePeak, setPickedDatePeak] = useState(new Date());
  const { id, aggr, meters, groups, facilities } = useContext(ParamsContext);
  const [datasetPeak, setDatasetPeak] = useState();
  const [minValue, setMinValue] = useState("");
  const [loading, setLoading] = useState();
  const [maxValue, setMaxValue] = useState("");
  const [initialValue, setInitialValue] = useState(0);
  const arr =
    aggr === "meter"
      ? meters
      : aggr === "group"
      ? groups
      : aggr === "facility" && facilities;
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      GetPowerPeak(id, aggr, pickedDatePeak)
        .then((res) => {
          setDatasetPeak(res.data);

          console.log(res.data);
        })
        .catch((err) => console.log(err))
        .finally(setLoading(false));
    }, 500);
    return () => clearTimeout(timer);
  }, [pickedDatePeak, id]);

  const handleChangeDatePeak = (val) => {
    setPickedDatePeak(val);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          maxHeight: "10rem",
        }}
      >
        <MultiDatePicker
          handleChangeDay={handleChangeDatePeak}
          pickedDate={pickedDatePeak}
        />
      </Box>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid lg={4} md={12} sx={{ display: "flex", flexWrap: "wrap" }}>
            {datasetPeak && arr && id && (
              <OrderedPowerPanel
                initialValue={initialValue}
                setInitialValue={setInitialValue}
                maxValue={maxValue}
                minValue={setMaxValue}
                setMinValue={setMinValue}
                setMaxValue={setMaxValue}
                datasetPeak={datasetPeak}
                ordered={arr.find((e) => e.id === id).ordered_power}
              />
            )}
          </Grid>
          <Grid lg={8} md={12}>
            {datasetPeak && arr && id && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "35rem",
                  height: "35rem",
                }}
              >
                <PowerPeakChart
                  initialValue={initialValue}
                  maxValue={maxValue}
                  datasetPeak={datasetPeak}
                  ordered={arr.find((e) => e.id === id).ordered_power}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PowerPeak;

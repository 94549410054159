import React, { useState, useEffect, useContext } from "react";
import RadioButtons from "../components/RadioButtons";
import { Box, Divider, Grid, LinearProgress, Typography } from "@mui/material";
import GetAllGroups from "../api/GetAllGroups";
import GetAllMeters from "../api/GetAllMeters";
import GroupSelector from "../components/GroupSelector";
import GetAllFacilities from "../api/GetAllFacilities";
import { useTheme } from "@emotion/react";
import GetEnergy from "../api/GetEnergy";
import FlexibleDatePicker from "../components/FlexibleDatePicker";
import EnergyChart from "../components/EnergyChart";
import { CircularProgress } from "@mui/material";
import RectangleIcon from "@mui/icons-material/Rectangle";
import { ParamsContext } from "./AnalyzePage";
import CostsPie from "../components/CostsPie";
const Energy = () => {
  const { id, aggr } = useContext(ParamsContext);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [dataset, setDataset] = useState();
  const [aggrType, setAggrType] = useState("day");
  const [loading, setLoading] = useState(false);
  const [energies, setEnergies] = useState();
  const [costs, setCosts] = useState();
  const theme = useTheme();
  console.log(id);
  const handleChangeAggrType = (val) => {
    setAggrType(val);
  };

  const handleChangeDay = (val) => {
    setPickedDate(val);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      console.log(pickedDate);
      GetEnergy(id, pickedDate, aggrType, aggr)
        .then((res) => {
          console.log(res.data);
          setDataset(res.data);
          let newArr = [];
          Object.keys(res.data)
            .filter((e) => e.indexOf("energy_total") == 0)
            .map((entry, index) => {
              if (res.data[entry] !== 0) {
                newArr.push({ name: entry, value: res.data[entry] });
              }
            });
          setEnergies(newArr);
          let newArrCosts = [];
          Object.keys(res.data)
            .filter(
              (e) =>
                e.indexOf("energy_price") == 0 || e.indexOf("power_fee") == 0
            )
            .map((entry, index) => {
              if (res.data[entry] !== 0) {
                newArrCosts.push({ name: entry, value: res.data[entry] });
              }
            });
          setCosts(newArrCosts);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }, 500);
    return () => clearTimeout(timer);
  }, [id, pickedDate, aggr, aggrType]);
  return (
    <>
      <Grid container>
        <Grid item lg={5} md={12}>
          {dataset && dataset.dataset.length > 0 && !loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                height: "40rem",
              }}
            >
              <Typography variant="h3">Rozkład zużycia</Typography>
              {energies && <CostsPie data={energies} />}
              <Divider flexItem />
              <Typography variant="h3">Rozkład kosztów</Typography>
              {costs && <CostsPie data={costs} />}
            </Box>
          )}
        </Grid>
        <Grid item lg={7} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "40rem",
            }}
          >
            <Divider flexItem sx={{ margin: "0.5rem" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {" "}
              <RadioButtons
                label="Typ wykresu"
                handleChangeAggr={handleChangeAggrType}
                aggr={aggrType}
                arr={[
                  { name: "Dzienny", value: "day" },
                  { name: "Miesięczny", value: "month" },
                  { name: "Roczny", value: "year" },
                ]}
              />
              {aggrType === "day" && (
                <FlexibleDatePicker
                  pickedDate={pickedDate}
                  handleChangeDate={handleChangeDay}
                  label={"Dzień"}
                  views={["day"]}
                  type="days"
                  inpFormat={"dd. MM. yyyy"}
                />
              )}
              {aggrType === "month" && (
                <FlexibleDatePicker
                  pickedDate={pickedDate}
                  handleChangeDate={handleChangeDay}
                  label={"Miesiąc"}
                  views={["month"]}
                  type="months"
                  inpFormat={"MMM. yyyy"}
                />
              )}
              {aggrType === "year" && (
                <FlexibleDatePicker
                  pickedDate={pickedDate}
                  handleChangeDate={handleChangeDay}
                  label={"Rok"}
                  views={["year"]}
                  type="years"
                  inpFormat={"yyyy"}
                />
              )}
            </Box>
            <Box sx={{ height: "40rem", width: "100%" }}>
              {loading ? (
                <LinearProgress />
              ) : (
                dataset && (
                  <EnergyChart
                    dataset={dataset}
                    aggrType={aggrType}
                    aggr={aggr}
                  />
                )
              )}
            </Box>

            {dataset && dataset.dataset.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <RectangleIcon
                    sx={{ color: theme.palette.primary.accentActive }}
                  />

                  <Typography
                    sx={{ color: theme.palette.primary.accentActive }}
                    variant="p"
                  >
                    Taryfa całodobowa
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <RectangleIcon sx={{ color: theme.palette.primary.alert }} />

                  <Typography
                    sx={{ color: theme.palette.primary.alert }}
                    variant="p"
                  >
                    Godziny szczytowe
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <RectangleIcon
                    sx={{ color: theme.palette.primary.success }}
                  />

                  <Typography
                    sx={{ color: theme.palette.primary.success }}
                    variant="p"
                  >
                    Godziny pozaszczytowe
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Energy;

import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { useTheme } from "@emotion/react";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload,
}) => {
  console.log(payload);
  const radius = outerRadius * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {payload.name.indexOf("energy_total") == 0
          ? payload.value > 1000000
            ? `${(payload.value / 1000000).toFixed(2)} MWh`
            : `${(payload.value / 1000).toFixed(2)} kWh`
          : `${payload.value.toFixed(2)} PLN`}
        {` (${(percent * 100).toFixed(0)}%)`}
      </text>
      <text
        x={x}
        y={y}
        dy={18}
        fill="#999"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {payload.name === "energy_total"
          ? "Taryfa całodobowa"
          : payload.name === "energy_total_1"
          ? "Poza szczytem"
          : payload.name === "energy_total_2"
          ? "Szczyt"
          : payload.name === "energy_price_total"
          ? "Taryfa całodobowa"
          : payload.name === "energy_price_total_1"
          ? "Poza szczytem"
          : payload.name === "energy_price_total_2"
          ? "Szczyt"
          : payload.name === "power_fee_total" && "Opłata mocowa"}
      </text>
    </>
  );
};
const CostsPie = ({ data }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer>
      <PieChart width={730} height={250}>
        <Pie
          data={data}
          dataKey="value"
          //   cx={100}
          //   cy={100}
          innerRadius={50}
          outerRadius={70}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              stroke=""
              fill={
                entry.name === "energy_total"
                  ? theme.palette.primary.main
                  : entry.name === "energy_total_1"
                  ? theme.palette.primary.success
                  : entry.name === "energy_total_2"
                  ? theme.palette.primary.alert
                  : entry.name === "energy_price_total"
                  ? theme.palette.primary.main
                  : entry.name === "energy_price_total_1"
                  ? theme.palette.primary.success
                  : entry.name === "energy_price_total_2"
                  ? theme.palette.primary.alert
                  : entry.name === "power_fee_total" && "purple"
              }
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CostsPie;

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { useTheme } from "@emotion/react";
import AnomaliesChart from "./AnomaliesChart";
import GetAnomaliesChart from "../api/GetAnomaliesChart";
import { Typography } from "@mui/material";
const AnomalyDialog = ({ anomaly, type }) => {
  const theme = useTheme();
  const [state, setState] = useState(false);
  const [dataset, setDataset] = useState();
  console.log(anomaly);
  console.log(type);
  console.log(state);
  useEffect(() => {
    if (anomaly && type && state === true) {
      console.log(state);
      GetAnomaliesChart(anomaly.id, type)
        .then((res) => {
          setDataset(res.data);
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [anomaly, state, type]);
  useEffect(() => {
    console.log(dataset);
  }, [dataset]);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  return (
    <div>
      <React.Fragment key="Bottom">
        <Button onClick={toggleDrawer(true)}>Zobacz</Button>
        <Drawer anchor={"bottom"} open={state} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              minHeight: "30rem",
              backgroundColor: theme.palette.primary.mainBackground,
              width: "100%",
              padding: "2rem",
            }}
          >
            <Typography variant="h3">Anomalie w zużyciu energii</Typography>
            <Typography variant="p" sx={{ opacity: 0.6 }}>
              System porównuje odpowiadające sobie dni tygodnia w poszukiwaniu
              anomalii w zużyciu
            </Typography>
            <Box
              sx={{
                height: "25rem",
                backgroundColor: theme.palette.primary.mainBackground,
                width: "100%",
              }}
            >
              {dataset && <AnomaliesChart dataset={dataset} />}
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
};
export default AnomalyDialog;

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Box, Typography } from "@mui/material";
import format from "date-fns/format";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import add from "date-fns/add";
import styled from "@emotion/styled";
import { PickersDay } from "@mui/x-date-pickers";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import isEqual from "date-fns/isEqual";
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.accentActive,
    color: theme.palette.primary.dark,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.accentActive,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.accent,
      },
    },

    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.accent,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));
const CustomTabs = ({ handleChangeDay, pickedDate, children, ...other }) => {
  const start = startOfWeek(pickedDate, { weekStartsOn: 1 });
  const end = endOfWeek(pickedDate, { weekStartsOn: 1 });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      {children}
      <Box
        {...other}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton
          onClick={() => {
            let temp = add(pickedDate, {
              days: -7,
            });
            handleChangeDay(temp);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h3">
          Od {format(start, "dd/MM")} do {format(end, "dd/MM")}
        </Typography>
        <IconButton
          onClick={() => {
            let temp = add(pickedDate, {
              days: 7,
            });
            handleChangeDay(temp);
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const WeekPicker = ({ pickedDate, handleChangeDay }) => {
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!pickedDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(pickedDate, { weekStartsOn: 1 });
    const end = endOfWeek(pickedDate, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(date, { start: start, end: end });
    const isFirstDay = isEqual(date, start, "day");
    const isLastDay = isEqual(date, end, "day");
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };
  return (
    <DatePicker
      views={["day"]}
      label="Tydzień"
      disableFuture={false}
      value={pickedDate}
      inputFormat="MMM. yyyy"
      renderDay={renderWeekPickerDay}
      components={{ PaperContent: CustomTabs }}
      PaperProps={{ sx: { display: "flex", flexDirection: "row" } }}
      componentsProps={{
        paperContent: { handleChangeDay, pickedDate },
      }}
      onChange={(newValue) => {
        handleChangeDay(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" helperText={null} />
      )}
    />
  );
};
export default WeekPicker;

import React, { useState, useEffect } from "react";
import GetAllGroups from "../api/GetAllGroups";
import { Grid, Button, Typography, Paper, Divider, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import AddDialog from "./AddDialog";
import PostGroup from "../api/PostGroup";
import GetAllFacilities from "../api/GetAllFacilities";
import FactoryIcon from "@mui/icons-material/Factory";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PostFacility from "../api/PostFacility";
import PatchGroup from "../api/PatchGroup";
import GroupSelector from "./GroupSelector";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteStructure from "../api/DeleteStructure";
import { useMediaQuery } from "@mui/material";
import AlertSnackbar from "./AlertSnackbar";
const GroupSettings = ({
  allFacilities,
  handleDeleteStructure,
  allGroups,
  reload,
  setReload,
}) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const [formData, setFormData] = useState();
  const [pickedFacility, setPickedFacility] = useState();
  const [id, setId] = useState();
  const [mode, setMode] = useState();
  const mediumViewport = useMediaQuery("(max-width:1460px)");
  const handleClickOpen = (val, val2, val3) => {
    setType(val);
    setMode(val2);
    setOpen(true);
    setFormData(val3);
  };
  const handlePatchGroup = (id, val) => {
    PatchGroup(id, val)
      .then((res) => {
        handleClose();
        setReload((prev) => !prev);
      })
      .catch((err) => {});
  };

  const handleChangeFacility = (val, val2) => {
    console.log(val, val2);
    setPickedFacility(val2);
    handlePatchGroup(val, { facility: val2 });
  };
  const handlePostGroup = (data) => {
    if (type === "group") {
      PostGroup(data)
        .then((res) => {
          handleClose();
          setReload((prev) => !prev);
        })
        .catch((err) => {});
    } else {
      PostFacility(data)
        .then((res) => {
          handleClose();
          setReload((prev) => !prev);
        })
        .catch((err) => {});
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ marginBottom: "2rem" }}>
        <Typography variant="h1">Obiekty i grupy</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            marginTop: "1rem",
          }}
        >
          <Button
            startIcon={<FactoryIcon />}
            onClick={() => handleClickOpen("facility")}
            variant="outlined"
            sx={{
              color: theme.palette.primary.accentActive,
              borderColor: theme.palette.primary.accentActive,
              fontWeight: "bold",
              "&:hover": {
                borderColor: theme.palette.primary.accent,
              },
            }}
          >
            Dodaj nowy obiekt
          </Button>
          <Button
            startIcon={<WorkspacesIcon />}
            onClick={() => handleClickOpen("group")}
            sx={{
              color: "rgba(255,255,255,0.9)",
              backgroundColor: theme.palette.primary.accentActive,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: theme.palette.primary.accent,
              },
            }}
          >
            Dodaj nową grupę
          </Button>
        </Box>
      </Box>
      <Grid
        item
        container
        lg={12}
        spacing={1}
        sx={{
          width: "100%",
          display: "flex",
          // flexDirection: "column",
          alignItems: "flex-start",
          justifyContent:
            allGroups && allGroups.length === 0 ? "center" : "flex-start",
          height: "100%",
          border: allGroups && allGroups.length === 0 && "solid",
          // height: "20rem",
          // minHeight: "20rem",

          borderColor: theme.palette.primary.accentActive,
        }}
      >
        {allFacilities &&
          allFacilities.map((el, i) => {
            return (
              <Grid
                key={i}
                item
                lg={6}
                md={12}
                sx={{
                  background: "none",
                  display: "flex",
                  padding: "1rem",
                  border: "solid",
                  borderColor: theme.palette.primary.accentActive,
                  flexDirection: "column",
                  minHeight: "20rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h3">{el.name}</Typography>
                  <AlertSnackbar
                    onClick={() => handleDeleteStructure("facility", el.id)}
                  />
                </Box>
                <Divider
                  sx={{
                    margin: "0.5rem",
                    backgroundColor: theme.palette.primary.accentActive,
                  }}
                />
                <Grid container spacing={5}>
                  {allGroups &&
                    allGroups.map((entry, index) => {
                      if (el.id === entry.facility) {
                        return (
                          <Grid
                            lg={6}
                            md={12}
                            item
                            key={index}
                            sx={{ padding: mediumViewport ? "1rem" : "2rem" }}
                          >
                            <Paper
                              sx={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "6rem",
                                // maxHeight: "6rem",
                                backgroundColor: theme.palette.primary.surface,
                                display: "flex",
                                padding: mediumViewport ? "0.5rem" : "1rem",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h3">
                                  {entry.name}
                                </Typography>
                                <AlertSnackbar
                                  onClick={() =>
                                    handleDeleteStructure("group", entry.id)
                                  }
                                />
                              </Box>
                              <Divider
                                sx={{
                                  margin: "0.5rem",
                                  backgroundColor:
                                    theme.palette.primary.accentActive,
                                }}
                              />
                              <Typography variant="p">
                                Moc zamówiona:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {entry.ordered_power
                                    ? entry.ordered_power
                                    : 0}
                                </span>{" "}
                                W
                              </Typography>
                              <Divider
                                sx={{
                                  margin: "0.5rem",
                                  backgroundColor:
                                    theme.palette.primary.accentActive,
                                }}
                              />
                              <Typography variant="p">
                                Próg anomalii:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {entry.anomaly_threshold_percentage
                                    ? entry.anomaly_threshold_percentage
                                    : 0}{" "}
                                </span>
                                %
                              </Typography>
                              <Divider
                                sx={{
                                  margin: "0.5rem",
                                  backgroundColor:
                                    theme.palette.primary.accentActive,
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: mediumViewport
                                    ? "column"
                                    : "row",
                                  justifyContent: "space-evenly",
                                  gap: mediumViewport && 1,
                                }}
                              >
                                <Button
                                  startIcon={<EditIcon />}
                                  onClick={() =>
                                    handleClickOpen("group", "edit", entry)
                                  }
                                  sx={{
                                    fontSize: "0.5rem",
                                    color: "rgba(255,255,255,0.9)",
                                    backgroundColor:
                                      theme.palette.primary.accentActive,
                                    // fontWeight: "bold",
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.primary.accent,
                                    },
                                  }}
                                >
                                  Edytuj
                                </Button>
                                <Button
                                  startIcon={<LinkOffIcon />}
                                  onClick={() => {
                                    setPickedFacility("");
                                    handlePatchGroup(entry.id, {
                                      facility: "",
                                    });
                                  }}
                                  sx={{
                                    fontSize: "0.5rem",
                                    color: "rgba(255,255,255,0.9)",
                                    backgroundColor:
                                      theme.palette.primary.accentActive,
                                    // fontWeight: "bold",
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.primary.accent,
                                    },
                                  }}
                                >
                                  Odepnij od obiektu
                                </Button>
                              </Box>
                            </Paper>
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            );
          })}
        {allGroups &&
          allGroups.map((entry, index) => {
            if (!entry.facility) {
              return (
                <Grid lg={3} item key={index} sx={{ padding: "2rem" }}>
                  <Paper
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      minHeight: "6rem",
                      // maxHeight: "6rem",
                      backgroundColor: theme.palette.primary.surface,
                      display: "flex",
                      padding: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h3">{entry.name}</Typography>
                      <AlertSnackbar
                        onClick={() => handleDeleteStructure("group", entry.id)}
                      />
                    </Box>
                    <Divider
                      sx={{
                        margin: "0.5rem",
                        backgroundColor: theme.palette.primary.accentActive,
                      }}
                    />
                    <Typography variant="p">
                      Moc zamówiona:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {entry.ordered_power ? entry.ordered_power : 0}
                      </span>{" "}
                      W
                    </Typography>
                    <Divider
                      sx={{
                        margin: "0.5rem",
                        backgroundColor: theme.palette.primary.accentActive,
                      }}
                    />
                    <Typography variant="p">
                      Próg anomalii:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {entry.anomaly_threshold_percentage
                          ? entry.anomaly_threshold_percentage
                          : 0}{" "}
                      </span>
                      %
                    </Typography>
                    <Divider
                      sx={{
                        margin: "0.5rem",
                        backgroundColor: theme.palette.primary.accentActive,
                      }}
                    />
                    {allFacilities && (
                      <GroupSelector
                        pickedGroup={pickedFacility}
                        label="Przypisz do obiektu"
                        handleChange={(e) => {
                          handleChangeFacility(entry.id, e.target.value);
                        }}
                        groups={allFacilities}
                      />
                    )}
                  </Paper>
                </Grid>
              );
            }
          })}
      </Grid>

      <AddDialog
        open={open}
        mode={mode}
        handleClose={handleClose}
        handlePostGroup={handlePostGroup}
        type={type}
        formData={formData}
        handlePatchGroup={handlePatchGroup}
      />
    </>
  );
};

export default GroupSettings;

import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Divider, ListItem } from "@mui/material";
import {
  ListSubheader,
  List,
  Box,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
const MultiSelector = ({
  pickedGroup,
  handleChange,
  groups,
  group,
  facilities,
  meters,
  name,
  label,
  onOpen,
  type,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (val) => {
    handleChange(val);
    setAnchorEl(null);
  };
  const [dataset, setDataset] = useState();

  useEffect(() => {
    let finalArr = facilities.map((entry, index) => {
      let arr = { groups: [], id: entry.id, name: entry.name };
      groups.map((el, i) => {
        if (el.facility === entry.id) {
          let temp = meters.filter((meter) => meter.group === el.id);
          arr.groups.push({ ...el, meters: temp });
        }
      });
      return arr;
    });
    setDataset(finalArr);
  }, []);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        variant="contained"
        onClick={handleClick}
      >
        {name ? name : "Wybierz"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ListSubheader>Obiekty</ListSubheader>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {dataset &&
            dataset.map((facility, index) => {
              console.log(facility);
              return (
                <>
                  <Box>
                    <MenuItem
                      key={facility.id}
                      sx={{ fontSize: "1.5rem" }}
                      onClick={() =>
                        handleClose({
                          id: facility.id,
                          name: facility.name,
                          type: "facility",
                        })
                      }
                    >
                      {facility.name}
                    </MenuItem>

                    {facility &&
                      facility.groups &&
                      facility.groups.map((group, i) => {
                        console.log(group);
                        return (
                          <>
                            <Divider />
                            <MenuItem
                              key={group.id}
                              onClick={() =>
                                handleClose({
                                  id: group.id,
                                  name: group.name,
                                  type: "group",
                                })
                              }
                            >
                              Grupa: {group.name}
                            </MenuItem>

                            {group &&
                              group.meters &&
                              group.meters.map((meter, i) => {
                                return (
                                  <MenuItem
                                    key={meter.id}
                                    onClick={() =>
                                      handleClose({
                                        id: meter.id,
                                        name: meter.name,
                                        type: "meter",
                                      })
                                    }
                                  >
                                    <li>
                                      <Typography
                                        sx={{ mt: 0.5, ml: 9 }}
                                        color="text.secondary"
                                        display="block"
                                        variant="caption"
                                      >
                                        {meter.name}
                                      </Typography>
                                    </li>
                                  </MenuItem>
                                );
                              })}
                          </>
                        );
                      })}
                  </Box>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ width: "5px" }}
                  />
                </>
              );
            })}
        </Box>
      </Menu>
    </div>
    // <FormControl fullWidth>
    //   <InputLabel id="demo-simple-select-label">{label}</InputLabel>
    //   <Select
    //     labelId="demo-simple-select-label"
    //     id="demo-simple-select"
    //     value={pickedGroup}
    //     onOpen={onOpen}
    //     label={label}
    //     renderValue={(props) => props.name}
    //     variant="outlined"
    //     onChange={handleChange}
    //   >
    //     {dataset.map((facility, index) => {
    //       return (
    //         <ListItem>
    //           <ListItemButton
    //             value={{
    //               id: facility.id,
    //               name: facility.name,
    //               type: "facility",
    //             }}
    //             key={facility.id}
    //             sx={{
    //               fontSize: "1.5rem",
    //             }}
    //           >
    //             <ListItemText primary={facility.name} />
    //             {facility.groups.map((group, i) => {
    //               return (
    //                 <ListItemButton
    //                   value={{
    //                     id: group.id,
    //                     name: group.name,
    //                     type: "facility",
    //                   }}
    //                   key={group.id}
    //                   sx={{
    //                     fontSize: "1.5rem",
    //                   }}
    //                 >
    //                   <ListItemText primary={group.name} />
    //                 </ListItemButton>
    //               );
    //             })}
    //           </ListItemButton>
    //         </ListItem>
    //       );
    //     })}
    //     {/* <ListItemButton>
    //       <ListItemText primary="Sent mail" />
    //     </ListItemButton>
    //     <ListItemButton>
    //       <ListItemText primary="Drafts" />
    //     </ListItemButton>
    //     <ListItemButton onClick={handleClick}>
    //       <ListItemText primary="Inbox" />
    //       {open ? <ExpandLess /> : <ExpandMore />}
    //     </ListItemButton>
    //     <Collapse in={open} timeout="auto" unmountOnExit>
    //       <List component="div" disablePadding>
    //         <ListItemButton sx={{ pl: 4 }}>
    //           <ListItemText primary="Starred" />
    //         </ListItemButton>
    //       </List>
    //     </Collapse> */}

    //     {/* <ListSubheader>Obiekty</ListSubheader>
    //     {dataset &&
    //       dataset.length > 0 &&
    //       dataset.map((entry, index) => {
    //         console.log(entry);
    //         return (
    //           <MenuItem
    //             value={{ id: entry.id, name: entry.name, type: "facility" }}
    //             key={entry.id}
    //             sx={{
    //               backgroundColor: group === entry.id && "rgba(0,0,0,0.1)",
    //               fontSize: "1.5rem",
    //             }}
    //           >
    //             {entry.name}
    //             {entry.groups.map((el, index) => {
    //               console.log(el);
    //               el.meters.map((e, i) => {
    //                 return (
    //                   <MenuItem
    //                     value={{ id: e.id, name: e.name, type: "meter" }}
    //                     key={e.id}
    //                     sx={{
    //                       fontSize: "1rem",
    //                     }}
    //                   >
    //                     {e.name}
    //                   </MenuItem>
    //                 );
    //               });
    //             })}
    //           </MenuItem>
    //         );
    //       })} */}
    //     {/* <ListSubheader>Grupy</ListSubheader>
    //     {groups.map((entry, index) => {
    //       console.log(entry);
    //       return (
    //         <MenuItem
    //           value={{ id: entry.id, name: entry.name, type: "group" }}
    //           key={entry.id}
    //           sx={{
    //             backgroundColor: group === entry.id && "rgba(0,0,0,0.1)",
    //           }}
    //         >
    //           {entry.name}
    //         </MenuItem>
    //       );
    //     })}
    //     <ListSubheader>Urządzenia</ListSubheader>
    //     {meters.map((entry, index) => {
    //       console.log(entry);
    //       return (
    //         <MenuItem
    //           value={{ id: entry.id, name: entry.name, type: "meter" }}
    //           key={entry.id}
    //           sx={{
    //             backgroundColor: group === entry.id && "rgba(0,0,0,0.1)",
    //           }}
    //         >
    //           {entry.name}
    //         </MenuItem>
    //       );
    //     })} */}
    //   </Select>
    // </FormControl>
  );
};

export default MultiSelector;

import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { format } from "date-fns";
import plLocale from "date-fns/locale/pl";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { pl } from "date-fns/locale";
import { useTheme } from "@emotion/react";
const THDChart = ({ dataset, type }) => {
  const theme = useTheme();
  const largeViewport = useMediaQuery("(min-width:1264px)");

  const gradientOffset = (val) => {
    const dataMax = Math.max(...val.map((i) => i.y));
    const dataMin = Math.min(...val.map((i) => i.y));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };
  const off = gradientOffset(dataset);

  const CustomTooltip = ({ active, payload, label, type }) => {
    if (active && payload && payload.length) {
      //
      //

      return (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.surface,
            borderRadius: "15px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography variant="h3">Godzina</Typography>
            <Typography variant="p">
              {format(new Date(label), "EEEE, HH:mm", { locale: pl })}
            </Typography>
          </Box>
          {type === "powerFactor" ? (
            payload.map((entry, index) => {
              return (
                <Box>
                  <Typography variant="p">
                    {" "}
                    {entry.dataKey === "y" ? "cos \u03C6" : "tg \u03C6"} ={" "}
                    {entry.value}{" "}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography variant="h3">Odkształcenie</Typography>

              <Typography variant="p">{`${payload[0].value} %`}</Typography>
            </Box>
          )}
        </Box>
      );
    }

    return null;
  };

  if (dataset.length > 0) {
    return (
      <>
        <ResponsiveContainer>
          <AreaChart
            height={500}
            width={1000}
            data={dataset}
            margin={{
              top: 50,
              right: 30,
              left: 30,
              bottom: 20,
            }}
          >
            {type === "powerFactor" && (
              <ReferenceLine
                y={0.4}
                isFront={true}
                ifOverflow="extendDomain"
                label={{
                  position: "insideBottomRight",
                  value: "tg \u03C6 = 0.4",
                  fill: theme.palette.primary.alert,
                  fontSize: 14,
                }}
                stroke={theme.palette.primary.alert}
              />
            )}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tick={{ fill: theme.palette.text.secondary }}
              minTickGap={50}
              dataKey="x"
              label={{
                fill: theme.palette.text.secondary,
                value: "Godzina i dzień tygodnia",
                angle: 0,
                fontSize: "15",
                offset: "-5",
                fontWeight: "300",
                position: "insideBottomLeft",
              }}
              tickFormatter={(value) => {
                //
                //
                if (value) {
                  return format(new Date(value), "HH:mm EEEE", { locale: pl });
                }
              }}
            />
            <YAxis
              tick={{ fill: theme.palette.text.secondary }}
              tickFormatter={(value) => {
                return value;
              }}
              label={{
                fill: theme.palette.text.secondary,
                value: type === "powerFactor" ? "Wartość" : `THD [%]`,
                angle: 0,
                fontSize: "15",
                offset: "15",
                fontWeight: "300",
                position: "top",
              }}
            />

            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset={off}
                  stopColor={theme.palette.primary.accentActive}
                  stopOpacity={0.5}
                />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient id="splitColor2" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset={off}
                  stopColor={theme.palette.primary.dark}
                  stopOpacity={0.5}
                />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="y"
              strokeWidth={2}
              stroke={theme.palette.primary.accentActive}
              name="Moc bierna indukcyjna"
              fill={"url(#splitColor)"}
              activeDot={{ r: 8 }}
            />
            {type === "powerFactor" && (
              <Area
                type="monotone"
                dataKey="tg"
                strokeWidth={2}
                stroke={theme.palette.text.secondary}
                name="Moc bierna indukcyjna"
                fill={"url(#splitColor2)"}
                activeDot={{ r: 8 }}
              />
            )}

            <Tooltip content={<CustomTooltip type={type} />} />
          </AreaChart>
        </ResponsiveContainer>
      </>
    );
  } else {
    return (
      <Box
        sx={{
          flexGrow: "1",
          display: "flex",
          marginTop: "10%",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", marginTop: "-10%" }}
        >
          Brak danych za wybrany okres
        </Typography>
      </Box>
    );
  }
};
export default THDChart;

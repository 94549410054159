import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Box, Typography } from "@mui/material";
import format from "date-fns/format";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import add from "date-fns/add";
import { useTheme } from "@emotion/react";

const CustomTabs = ({ handleChangeDate, pickedDate, children, ...other }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <Box
        {...other}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton
          onClick={() => {
            let temp = add(pickedDate, {
              years: -1,
            });
            handleChangeDate(temp);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h3">{format(pickedDate, "yyyy")}</Typography>
        <IconButton
          onClick={() => {
            let temp = add(pickedDate, {
              years: 1,
            });
            handleChangeDate(temp);
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
};

const MultiDatePicker = ({ pickedDate, handleChangeDate }) => {
  const theme = useTheme();
  return (
    <DatePicker
      views={["month"]}
      label="Miesiąc"
      disableFuture={true}
      value={pickedDate}
      inputFormat="MMM. yyyy"
      components={{ PaperContent: CustomTabs }}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "row",
          "& .PrivatePickersMonth-root": {
            // backgroundColor: theme.palette.primary.accentActive,
            color: theme.palette.primary.dark,
            "&.Mui-selected": {
              backgroundColor: theme.palette.primary.accentActive,
              "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.accent,
              },
            },
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.accent,
            },
          },
        },
      }}
      componentsProps={{
        paperContent: { handleChangeDate, pickedDate },
      }}
      onChange={(newValue) => {
        handleChangeDate(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" helperText={null} />
      )}
    />
  );
};
export default MultiDatePicker;

import React, { useEffect, useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import GroupSelector from "./GroupSelector";
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { IconButton, Button } from "@mui/material";
import { format } from "date-fns";
import AnomalyDialog from "./AnomalyDialog";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.8) 100%)`,
  width: "100%",
  height: "100%",
  padding: "1rem",
  minHeight: "25rem",
  ".MuiDataGrid-columnHeaders": {
    ".MuiDataGrid-iconButtonContainer": {
      visibility: "visible",
    },
    // backgroundColor: theme.palette.primary.mainBackground,
    background: theme.palette.primary.accentActive,
    ":not(.MuiDataGrid-columnHeader--sorted)": {
      ".MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "rgba(255,255,255,0.4)",
      },
    },
    ".MuiDataGrid-columnHeader--sorted": {
      ".MuiDataGrid-sortIcon": {
        opacity: 1,
        color: "rgba(255,255,255,1)",
      },
    },
  },

  ".MuiDataGrid-columnSeparator": {
    display: "none",
  },

  "& div div div div >.MuiDataGrid-cell:focus": {
    outline: "none",
  },

  "& div div div div >.MuiDataGrid-cell": {
    borderBottom: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
    borderRight: `1px solid linear-gradient(-45deg, rgba(0,93,138,0.5) 0%, rgba(0,93,138,0.6) 100%)`,
  },

  "	.MuiDataGrid-menuIconButton": {
    display: "none",
  },
}));

const AnomaliesDataGrid = ({ data, loading, type }) => {
  console.log(type);
  const theme = useTheme();
  const columns = [
    {
      field: "name",
      headerName: type === "group" ? "Nazwa grupy" : "Nazwa licznika",
      flex: 0.7,
      sortable: false,
    },
    {
      field: "cap_dt",
      headerName: "Anomalia w dniu",
      flex: 0.7,
      sortable: true,
      renderCell: (params) => {
        console.log(params.value);
        return format(new Date(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "mean",
      headerName: "Średnia [kWh]",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        console.log(params);
        return parseFloat(params.value).toFixed(0);
      },
    },
    {
      field: "value",
      headerName: "Zarejestrowane zużycie [kWh]",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        console.log(params);
        return parseFloat(params.value).toFixed(0);
      },
    },
    {
      field: "deviation",
      headerName: "Odchylenie [%]",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        console.log(params);
        return parseFloat(params.value).toFixed(0);
      },
    },
    {
      field: "",
      headerName: "",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        return <AnomalyDialog anomaly={params.row} type={type} />;
      },
    },
  ];

  return (
    <>
      <CustomDataGrid
        rows={data}
        {...data}
        loading={loading}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        checkboxSelection={false}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "cap_dt", sort: "desc" }],
          },
        }}
        ColumnMenuIcon={false}
        sx={{
          "& .MuiDataGrid-row": {
            "&.Mui-selected": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </>
  );
};

export default AnomaliesDataGrid;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import GetAllMeters from "../api/GetAllMeters";
import PriceListAssigner from "./PriceListAssigner";
import AssingToPriceList from "../api/AssingToPriceList";
const StyledTextField = styled(TextField)(({ theme }) => ({
  // marginTop:'1rem',
  "& .MuiInputBase-input": {
    color: "#212528",
  },
  "& label.Mui-focused": {
    color: "#212528",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.25rem",
    color: "#171D22",
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.tertiary,
  },
}));
const EditPriceListDialog = ({ open, handleClose, priceListId }) => {
  const theme = useTheme();
  const [left, setLeft] = React.useState();
  const [right, setRight] = React.useState();

  useEffect(() => {
    GetAllMeters().then((res) => {
      setRight(res.data.filter((e) => e.price_list.length === 0));
      setLeft(res.data.filter((e) => e.price_list[0] === priceListId));
      console.log(res.data);
    });
  }, [priceListId]);

  const handleAssign = () => {
    left.map((entry, index) => {
      return AssingToPriceList({
        pricelist_id: priceListId,
        object_id: entry.id,
      });
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.text.tertiary }}>
        Dodaj
      </DialogTitle>
      <DialogContent>
        {left && right && (
          <PriceListAssigner
            left={left}
            setLeft={setLeft}
            setRight={setRight}
            right={right}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Anuluj</Button>
        <Button onClick={handleAssign}>Zapisz</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPriceListDialog;

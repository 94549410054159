import { createTheme } from "@mui/material/styles";
import { getPalette } from "./getPalette";
import { useState, useEffect, useMemo, createContext } from "react";
import { ThemeProvider } from "@emotion/react";
export const ColorModeContext = createContext({ toggleColorMode: () => {} });
const ColorProvider = ({ children }) => {
  // const [mode, setMode] = useState(localStorage.getItem("palette") || "light");

  // const colorMode = useMemo(
  //   () => ({
  //     toggleColorMode: () => {
  //       setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  //     },
  //   }),
  //   []
  // );
  const mode = "dark";
  const theme = useMemo(() => createTheme(getPalette(mode)), [mode]);
  useEffect(() => {
    localStorage.setItem("palette", mode);
  }, [mode]);
  theme.typography.h1 = {
    color: theme.palette.text.primary,
    fontSize: "1.875rem",
    fontFamily: ["Rubik, sans-serif"].join(","),
  };
  theme.typography.h2 = {
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
    fontFamily: ["Rubik, sans-serif"].join(","),
  };
  theme.typography.h3 = {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: ["Rubik, sans-serif"].join(","),
  };
  theme.typography.p = {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontFamily: ["Rubik, sans-serif"].join(","),
  };
  return (
    <ColorModeContext.Provider
      value={{
        theme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
export default ColorProvider;

import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel } from "@mui/material";
import { useTheme } from "@emotion/react";
import RadioButtons from "./RadioButtons";
const ControlledCheckbox = ({
  handleChange,
  checked,
  unit,
  handleChangeUnit,
}) => {
  const theme = useTheme();
  return (
    <FormGroup>
      <FormControlLabel
        sx={{ color: theme.palette.primary.dark }}
        control={
          <Checkbox
            checked={checked.energy}
            onChange={handleChange}
            name="energy"
          />
        }
        label="Zużycie energii"
      />
      <RadioButtons
        handleChangeAggr={handleChangeUnit}
        aggr={unit}
        label="Jednostka danych"
        arr={[
          { name: "kWh", value: "0" },
          { name: "MWh", value: "1" },
        ]}
      />
      <FormControlLabel
        sx={{ color: theme.palette.primary.dark }}
        control={
          <Checkbox
            checked={checked.power}
            onChange={handleChange}
            name="power"
          />
        }
        label="Moc zamówiona"
      />
      <FormControlLabel
        sx={{ color: theme.palette.primary.dark }}
        control={
          <Checkbox
            checked={checked.anomaly}
            onChange={handleChange}
            name="anomaly"
          />
        }
        label="Anomalie"
      />
    </FormGroup>
  );
};
export default ControlledCheckbox;

import { api } from "../utils/AuthManager";
const GetProfile = async (id, aggr) => {
  return api
    .get(`/api/consumption_profile/${id}/`, {
      params: {
        aggr: aggr,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetProfile;

import { api } from "../utils/AuthManager";
const GetChecklist = async (id, aggr) => {
  return api
    .get(`/api/checklist/${id}`, {
      params: {
        aggr: aggr,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetChecklist;

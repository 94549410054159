import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@emotion/react";
import { InputBase } from "@mui/material";
import styled from "@emotion/styled";

const GroupSelector = ({
  pickedGroup,
  handleChange,
  groups,
  group,
  label,
  onOpen,
  type,
}) => {
  const theme = useTheme();
  return (
    <FormControl
      fullWidth
      sx={{ backgroundColor: type && theme.palette.primary.mainBackground }}
    >
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          "&.Mui-focused": {
            color: theme.palette.primary.dark,
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={pickedGroup}
        onOpen={onOpen}
        label={label}
        variant="outlined"
        onChange={(e, i) => handleChange(e, i)}
      >
        <MenuItem
          sx={{ borderBottom: "solid", borderBottomColor: "#1976D2" }}
          value={null}
        >
          Żadna
        </MenuItem>
        {groups.map((entry, index) => {
          return (
            <MenuItem
              value={entry.id}
              key={entry.name}
              sx={{
                backgroundColor: group === entry.id && "rgba(255,255,255,0.1)",
              }}
            >
              {entry.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default GroupSelector;

import { useState } from "react";
import styled from "@emotion/styled";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Grid, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import EditPriceListDialog from "./EditPriceListDialog";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.surface,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const PriceListsAccordion = ({ pricelists }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();

  const handleOpen = (val) => {
    setOpen(true);
    setId(val);
  };
  const handleClose = () => {
    setOpen(false);
    setId("");
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {pricelists.map((entry, index) => {
        // console.log(entry);
        return (
          <Accordion
            key={index}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                ".MuiAccordionSummary-content": {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              }}
            >
              <Typography variant="h3">{entry.tariff_name}</Typography>
              <Typography variant="p">
                Obowiązuje: {entry.start_date} - {entry.end_date}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h3">Stawki</Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="p">
                        Opłata sieciowa zmienna
                      </Typography>
                      <Typography variant="p">
                        {!entry.energy_price_3 && !entry.energy_price_2
                          ? "Całodobowa"
                          : !entry.energy_price_3 && entry.energy_price_2
                          ? "Szczytowa "
                          : entry.energy_price_3 &&
                            entry.energy_price_2 &&
                            "Szczyt przedpołudniowy"}
                      </Typography>
                      <Typography variant="p">
                        {entry.energy_price_1}
                      </Typography>
                    </Box>
                    {entry.energy_price_2 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <Typography variant="p">
                          Opłata sieciowa zmienna
                        </Typography>
                        <Typography variant="p">Pozaszczytowa</Typography>
                        <Typography variant="p">
                          {entry.energy_price_2}
                        </Typography>
                      </Box>
                    )}
                    {entry.energy_price_3 && entry.energy_price_2 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {" "}
                        <Typography variant="p">
                          Opłata sieciowa zmienna
                        </Typography>
                        <Typography variant="p">Szczyt popołudniowy</Typography>
                        <Typography variant="p">
                          {entry.energy_price_3}
                        </Typography>
                      </Box>
                    )}
                    {entry.power_fee && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="p">Opłata mocowa</Typography>
                        <Typography variant="p"> </Typography>
                        <Typography variant="p">{entry.power_fee}</Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    {/* <Button
                      sx={{
                        color: "rgba(255,255,255,0.9)",
                        backgroundColor: theme.palette.primary.accentActive,
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.accent,
                        },
                      }}
                      onClick={() => handleOpen(entry.id)}
                    >
                      Edytuj
                    </Button> */}
                    {/* <Button
                      sx={{
                        color: "rgba(255,255,255,0.9)",
                        backgroundColor: theme.palette.primary.accentActive,
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.accent,
                        },
                      }}
                    >
                      Edytuj
                    </Button> */}
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <EditPriceListDialog
        handleClose={handleClose}
        open={open}
        priceListId={id}
      />
    </div>
  );
};
export default PriceListsAccordion;

import { api } from "../utils/AuthManager";
const GetPvDashboard = async () => {
  return api
    .get(`/api/pv_dashboard/`)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPvDashboard;

import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  InputAdornment,
} from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import TariffSelector from "./TariffSelector";
import TariffDatePicker from "./TariffDatePicker";
import format from "date-fns/format";
const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,

  "& .MuiInputBase-input": {
    color: "#212528",
  },
  "& label.Mui-focused": {
    color: "#212528",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.25rem",
    color: "#171D22",
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.tertiary,
  },
}));
const StyledTextFieldMini = styled(TextField)(({ theme }) => ({
  marginTop: "0",
  marginBottom: "-0.2rem",
  "& .MuiInputBase-input": {
    color: "#212528",
  },
  "& label.Mui-focused": {
    color: "#212528",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(33,37,40,0.5)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#212528",
  },
  "& .MuiInputLabel-root": {
    fontSize: "1rem",
    color: "#171D22",
    fontFamily: ["Rubik, sans-serif"].join(","),
  },
  "& .MuiFormHelperText-root": {
    color: theme.palette.text.tertiary,
  },
}));
const AddPriceList = ({
  open,
  handleClose,
  handlePostPricelist,
  allTariffs,
}) => {
  const theme = useTheme();
  const [pickedTariff, setPickedTariff] = useState();
  const [availableFields, setAvailableFields] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [data, setData] = useState({
    name: null,
    tariff: 0,
    energy_price_1: "",
    energy_price_2: "",
    energy_price_3: "",
    power_fee: "",
    start_date: "",
    end_date: "",
    distribution_fee: 1,
  });
  const tariffs = [
    { id: 1, name: "C11" },
    { id: 2, name: "C12a" },
    { id: 3, name: "C12b" },
    { id: 4, name: "C21" },
    { id: 5, name: "C22a" },
  ];
  useEffect(() => {
    if (pickedTariff) {
      let temp = parseInt(pickedTariff.tariff_name.charAt(2));
      switch (temp) {
        case 1:
          setAvailableFields(["Całodobowa"]);
          break;
        case 2:
          setAvailableFields(["Szczytowa", "Pozaszczytowa"]);
          break;

        case 3:
          setAvailableFields([
            "Szczyt przedpołudniowy",
            "Pozaszczytowa",
            "Szczyt popołudniowy",
          ]);
          break;
        default:
          console.log("done");
      }
    }
  }, [pickedTariff]);
  const handleChange = (e) => {
    setPickedTariff(e.target.value);
    setData({ ...data, tariff: e.target.value.id });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setPickedTariff();
        handleClose();
      }}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.dark,
          minWidth: "50rem",
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.text.tertiary }}>
        Dodaj
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <StyledTextField
          autoFocus
          margin="dense"
          id="name"
          label="Nazwa"
          type="text"
          fullWidth
          required
          variant="standard"
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
        />
        <TariffSelector
          handleChange={handleChange}
          groups={allTariffs}
          label="Wybierz taryfę"
          pickedGroup={pickedTariff}
          type="tariff"
        />
        {pickedTariff && (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: theme.palette.primary.surface }}
                >
                  Stawki
                </Typography>
                {pickedTariff &&
                  availableFields &&
                  availableFields.map((entry, index) => {
                    // console.log(entry);
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="p"
                          sx={{ color: theme.palette.primary.surface }}
                        >
                          Opłata sieciowa zmienna
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ color: theme.palette.primary.surface }}
                        >
                          {entry}
                        </Typography>
                        <StyledTextFieldMini
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Typography
                                  variant="p"
                                  sx={{ color: theme.palette.primary.surface }}
                                >
                                  PLN/kWh
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                          autoFocus
                          margin="dense"
                          id="price"
                          type="number"
                          required
                          variant="standard"
                          name={
                            index === 0
                              ? "energy_price_1"
                              : index === 1
                              ? "energy_price_2"
                              : index === 2 && "energy_price_3"
                          }
                          value={
                            index === 0
                              ? data.energy_price_1
                              : index === 1
                              ? data.energy_price_2
                              : index === 2 && data.energy_price_3
                          }
                          onChange={(e) => {
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    );
                  })}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ color: theme.palette.primary.surface }}
                  >
                    Opłata mocowa
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: theme.palette.primary.surface }}
                  >
                    {" "}
                  </Typography>
                  <StyledTextFieldMini
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            variant="p"
                            sx={{ color: theme.palette.primary.surface }}
                          >
                            PLN/kWh
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    margin="none"
                    id="power_fee"
                    // label="Nazwa"
                    type="number"
                    required
                    variant="standard"
                    value={data.power_fee}
                    onChange={(e) => {
                      setData({ ...data, power_fee: e.target.value });
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ color: theme.palette.primary.surface }}
                >
                  Okres obowiązywania
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "flex-start",
                    gap: 5,
                  }}
                >
                  <TariffDatePicker
                    setData={(val) =>
                      setData({
                        ...data,
                        start_date: format(val, "yyyy-MM-dd"),
                      })
                    }
                    startDate={startDate}
                    setStartDate={setStartDate}
                    label="Data rozpoczęcia"
                  />
                  <TariffDatePicker
                    setData={(val) =>
                      setData({ ...data, end_date: format(val, "yyyy-MM-dd") })
                    }
                    startDate={endDate}
                    setStartDate={setEndDate}
                    label="Data zakończenia"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setData({
              name: null,
              tariff: 0,
              energy_price_1: "",
              energy_price_2: "",
              energy_price_3: "",
              power_fee: "",
              start_date: "",
              end_date: "",
              distribution_fee: 1,
            });
            handleClose();
          }}
        >
          Anuluj
        </Button>
        <Button
          onClick={() => {
            handlePostPricelist(data);
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPriceList;

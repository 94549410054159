import React, { useContext } from "react";
import { Outlet } from "react-router";
import Grid from "@mui/material/Grid";
import { ColorModeContext } from "../utils/globalTheme";
import { useTheme } from "@emotion/react";
import Navbar from "../components/Navbar";
import Typography from "@mui/material/Typography";
const Main = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
      }}
    >
      <Grid item lg={2} md={3} xs={12} sm={12}>
        <Navbar />
      </Grid>
      <Grid
        container
        item
        lg={10}
        md={9}
        xs={12}
        sm={12}
        sx={{
          backgroundColor: theme.palette.primary.mainBackground,
          padding: "5rem",
          flexGrow: 1,
          paddingBottom: "1rem",
          // height: "100%",
        }}
      >
        {" "}
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Main;

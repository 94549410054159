import React, { useState } from "react";
import { Typography, Box, Grid, TextField, Button } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Divider } from "@mui/material";
const IncrementerButton = styled(Button)(({ theme }) => ({
  maxWidth: "1rem",
  height: "1rem",
  backgroundColor: theme.palette.primary.dark,
  "&:hover": {
    backgroundColor: theme.palette.text.secondary,
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input[type="number"]::-webkit-inner-spin-button': {
    display: "none",
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  minHeight: "2rem",
  width: "100%",
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
  },
  "& label.Mui-focused": {
    color: theme.palette.text.primary,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.text.secondary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.text.primary,
  },
  "& .MuiInputLabel-root": {
    fontSize: "1rem",
    fontFamily: ["Roboto, sans-serif"].join(","),
  },
}));
const OrderedPowerPanel = ({
  datasetPeak,
  ordered,
  initialValue,
  setInitialValue,
  maxValue,
  setMaxValue,
  setMinValue,
}) => {
  const [avg, setAvg] = useState("");

  React.useMemo(() => {
    const sum = datasetPeak.reduce((a, b) => a + b.y, 0);
    const tempAvg = (sum / datasetPeak.length).toFixed(2) || 0;
    const findMax = (arr) => {
      let temp = Math.max.apply(
        Math,
        arr.map((i) => {
          return i.y;
        })
      );
      setMaxValue(temp);
      setAvg(tempAvg);
    };
    const findMin = (arr) => {
      if (datasetPeak.find((el) => el.min < 0)) {
        let temp = Math.min.apply(
          Math,
          arr.map((i) => {
            return i.min;
          })
        );
        setMinValue(temp);
      }
    };
    findMax(datasetPeak);
    findMin(datasetPeak);
  }, [datasetPeak]);
  const theme = useTheme();
  const timer = React.useRef(null);
  const decrementTimer = React.useRef(null);
  const increment = () => {
    timer.current = setInterval(
      () =>
        //
        setInitialValue((prev) => {
          let min = 0;
          let value = parseInt(prev);
          let max = (ordered - maxValue) / 1000;
          if (value >= max) {
            return max.toFixed(2);
          } else {
            return (value + 1).toFixed(2);
          }
        }),
      1
    );
  };
  const decrement = () => {
    decrementTimer.current = setInterval(
      () =>
        setInitialValue((prev) => {
          let value = parseInt(prev);
          let min = 0;
          let max = (ordered - maxValue) / 1000;

          if (value <= min) {
            return min.toFixed(2);
          } else {
            return (value - 1).toFixed(2);
          }
        }),
      0.5
    );
  };
  const timeoutClear = () => {
    clearInterval(timer.current);
  };
  const timeoutDecrementClear = () => {
    clearInterval(decrementTimer.current);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">Twoja moc zamówiona</Typography>
        <Typography variant="p" sx={{ color: theme.palette.text.primary }}>
          {ordered / 1000} kW
        </Typography>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">
          Twoja miesięczna opłata za moc zamówioną
        </Typography>
        <Typography variant="p">{11.09} PLN/kW</Typography>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">Średnia z wartości maksymalnych</Typography>
        <Typography variant="p">{(avg / 1000).toFixed(2)} kW</Typography>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">Miesięcznie płacisz</Typography>
        <Typography variant="p">
          {((ordered / 1000) * 11.54)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
          PLN
        </Typography>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">
          O ile chciałbyś obniżyć moc zamówioną?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StyledTextField
            InputProps={{
              inputProps: {
                min: 0,
                max: ordered - maxValue,
              },
            }}
            type="number"
            id="standard-basic-1"
            label="Ilość kW"
            value={parseInt(initialValue)}
            variant="standard"
            onChange={(e) => {
              //
              let value = parseInt(e.target.value, 10);
              let min = 0;
              let max = (ordered - maxValue) / 1000;
              if (value > max) value = max;
              if (value < min) value = min;
              setInitialValue(value.toFixed(2));
            }}
          />
          <Divider
            sx={{
              // margin: "1rem",
              height: "0.2rem",
              backgroundColor: theme.palette.primary.accentActive,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <IncrementerButton
              onMouseLeave={timeoutClear}
              onMouseUp={timeoutClear}
              onMouseDown={increment}
              // onClick={() => {
              //   let value = initialValue;
              //   let min = 0;
              //   let max =
              //     (device.configuration.ordered_power -
              //       maxValue) /
              //     1000;
              //   if (value > max) value = max;
              //   if (value < min) value = min;
              //   setInitialValue(initialValue + 1);
              // }}
            >
              <AddIcon sx={{ color: theme.palette.primary.surface }} />
            </IncrementerButton>
            <IncrementerButton
              onMouseLeave={timeoutDecrementClear}
              onMouseUp={timeoutDecrementClear}
              onMouseDown={decrement}
            >
              <RemoveIcon sx={{ color: theme.palette.primary.surface }} />
            </IncrementerButton>
          </Box>
        </Box>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "flex-start",
          padding: "1rem",
        }}
      >
        <Typography variant="p">Oszczędność miesięczna</Typography>
        <Typography variant="h7" sx={{ color: theme.palette.primary.success }}>
          {initialValue
            ? `${(
                (ordered / 1000) * 11.54 -
                (ordered / 1000 - initialValue) * 11.54
              )
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} PLN`
            : "0"}
        </Typography>
        <Divider
          sx={{
            // margin: "1rem",
            height: "0.2rem",
            backgroundColor: theme.palette.primary.accentActive,
          }}
        />
      </Box>
    </Box>
  );
};

export default OrderedPowerPanel;
